import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DeprecatedSelect, ISelectOption } from '../../../../web/components/DeprecatedSelect';
import { Toggle } from '../../../../web/components/Toggle';
import { EditItemPenIcon } from '../../../../web/components/svgs/icons/EditItemPenIcon';
import { brandPrimaryLight, grayIconFill } from '../../../styles/colors';
import { LeadRuleViewModel, RecurrenceType, TurnThisOnOptions } from '../../../viewModels/leadrules';
import { TransparentButton } from '../../TransparentButton';
import { Fill } from '../../leadView/components/Fill';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import {
	hourOptions,
	numberRange,
	prepareFromWeeklySchedule,
	prepareToWeeklySchedule,
	turnThisOnOptions,
} from '../utils';
import { IScheduleInterval, ScheduleModal } from './ScheduleModal';
import { SetCustomValueModal } from './SetCustomValueModal';

interface IProps {
	leadRule: LeadRuleViewModel;
}

export const generateCustomValueOption = (value: number): ISelectOption<number> => ({
	dataContext: value,
	id: 'custom-value',
	text: String(value),
});

export const getSelectedOption = (leadRulePropValue: number, options: ISelectOption<number>[] = numberRange) => {
	const defaultOption = options.find(item => item.id === 'default');
	const existentOption = options.find(item => item.dataContext === leadRulePropValue);
	const selected = existentOption
		? existentOption
		: leadRulePropValue
			? generateCustomValueOption(leadRulePropValue)
			: defaultOption;
	return selected as ISelectOption<number>;
};

export const RuleContainerHeader: React.FC<IProps> = observer(({ leadRule }) => {
	const defaultTurnThisOn = turnThisOnOptions[0];
	const defaultDuration = hourOptions[0];
	const [selectedTurnThisOn, setTurnThisOn] = useState<ISelectOption<number>>(defaultTurnThisOn);
	const [selectedDuration, setDuration] = useState<ISelectOption<number>>(defaultDuration);

	const [scheduleModal, setScheduleModal] = useState(false);
	const [schedule, setSchedule] = useState(null);

	const [selectedMaxPerHr, setMaxPerHr] = useState(null);
	const [selectedMaxPerDay, setMaxPerDay] = useState(null);

	const secondaryTurnThisOn = turnThisOnOptions[1];

	useEffect(() => {
		if (leadRule?.recurrence === RecurrenceType.AlwaysOn) {
			setTurnThisOn(defaultTurnThisOn);
			setDuration(defaultDuration);
		}
		if (leadRule?.recurrence === RecurrenceType.SingleInterval) {
			const max = leadRule?.scheduleMax?.split(':')?.[0];
			const min = leadRule?.scheduleMin?.split(':')?.[0];
			const diff = parseInt(max, 10) - parseInt(min, 10);
			const durationTime = hourOptions.filter(item => item.dataContext === diff)?.[0];
			setTurnThisOn(defaultTurnThisOn);
			setDuration(durationTime);
		}
		if (leadRule?.recurrence === RecurrenceType.Weekly) {
			setTurnThisOn(secondaryTurnThisOn);
			setDuration(defaultDuration);
			const prepared = [...(leadRule?.weeklySchedule ?? [])].map(prepareFromWeeklySchedule);

			setSchedule(prepared);
		}

		setMaxPerDay(getSelectedOption(leadRule?.dailyLimit));

		setMaxPerHr(getSelectedOption(leadRule?.hourlyLimit));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadRule]);

	useEffect(() => {
		if (!schedule) {
			setTurnThisOn(defaultTurnThisOn);
		} else {
			setTurnThisOn(secondaryTurnThisOn);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schedule]);

	const handleEnable = () => {
		leadRule?.setEnabled(!leadRule?.isEnabled);
	};

	const handleTurnThisOn = (option: ISelectOption<number>) => {
		setTurnThisOn(option);
		if (option.id === TurnThisOnOptions.Scheduled) {
			setScheduleModal(true);
		} else {
			handleDuration(defaultDuration);
			leadRule?.setSchedule(null);
			leadRule?.setRecurrence(RecurrenceType.AlwaysOn);
		}
	};

	const handleDuration = (option: ISelectOption<number>) => {
		if (option.id === RecurrenceType.AlwaysOn) {
			leadRule?.setSchedule(null);
			leadRule?.setRecurrence(RecurrenceType.AlwaysOn);
		} else {
			leadRule?.setSchedule(option.dataContext);
			leadRule?.setRecurrence(RecurrenceType.SingleInterval);
		}

		leadRule?.setWeeklySchedule(null);
		setDuration(option);
	};

	const handleMaxPerHr = (option: ISelectOption<number>) => {
		if (option.id !== 'custom') {
			leadRule.setHourlyLimit(option.dataContext);

			setMaxPerHr(option);
			return;
		}

		setMaxPerHr(option);
	};

	const handleMaxPerDay = (option: ISelectOption<number>) => {
		if (option.id !== 'custom') {
			leadRule.setDailyLimit(option.dataContext);

			setMaxPerDay(option);
			return;
		}

		setMaxPerDay(option);
	};

	const handleModalClose = () => {
		setScheduleModal(false);
		if (!schedule) {
			setTurnThisOn(defaultTurnThisOn);
		}
	};

	const handleScheduleSave = (savedSchedule: IScheduleInterval[]) => {
		setSchedule(savedSchedule);
		setScheduleModal(false);
		const prepared = [...savedSchedule].map(prepareToWeeklySchedule);
		leadRule?.setRecurrence(RecurrenceType.Weekly);
		leadRule?.setSchedule(null);

		leadRule?.setWeeklySchedule(prepared);
	};

	const handleEditSchedule = () => setScheduleModal(true);

	const showDuration = selectedTurnThisOn?.id === TurnThisOnOptions.RightAway;

	const isScheduled = selectedTurnThisOn?.id === TurnThisOnOptions.Scheduled;

	return (
		<div className={css(styleSheet.ruleContainerHeader)}>
			<Group vCentered={true}>
				<div className={css(styleSheet.regularText)}>Turn this on:</div>
				<DeprecatedSelect
					styles={[styleSheet.regularSelect]}
					onOptionClick={handleTurnThisOn}
					options={turnThisOnOptions}
					selectedOption={selectedTurnThisOn}
				/>

				{showDuration && (
					<>
						<div className={css(styleSheet.regularText)}>Duration:</div>
						<DeprecatedSelect
							styles={[styleSheet.mediumSelect]}
							onOptionClick={handleDuration}
							options={hourOptions}
							selectedOption={selectedDuration}
						/>
					</>
				)}

				{schedule && isScheduled && (
					<TransparentButton onClick={handleEditSchedule}>
						<Group spacingSize={5}>
							<EditItemPenIcon fillColor={brandPrimaryLight} />
							<div className={css(styleSheet.regularText, styleSheet.disabled)}>Edit schedule</div>
						</Group>
					</TransparentButton>
				)}

				<div className={css(styleSheet.regularText)}>Max leads/hr:</div>
				<div className={css(styleSheet.fieldWrapper)}>
					<DeprecatedSelect
						styles={[styleSheet.timeSelect]}
						onOptionClick={handleMaxPerHr}
						options={numberRange}
						selectedOption={selectedMaxPerHr}
					/>

					{selectedMaxPerHr?.id === 'custom' ? (
						<SetCustomValueModal
							onClickToClose={() => setMaxPerHr(getSelectedOption(leadRule?.hourlyLimit))}
							onClickToSave={value => {
								setMaxPerHr(generateCustomValueOption(Number(value)));
								leadRule.setHourlyLimit(Number(value));
							}}
						/>
					) : null}
				</div>
				<div className={css(styleSheet.regularText)}>Max leads/day:</div>
				<div className={css(styleSheet.fieldWrapper)}>
					<DeprecatedSelect
						styles={[styleSheet.timeSelect]}
						onOptionClick={handleMaxPerDay}
						options={numberRange}
						selectedOption={selectedMaxPerDay}
					/>

					{selectedMaxPerDay?.id === 'custom' ? (
						<SetCustomValueModal
							onClickToClose={() => setMaxPerDay(getSelectedOption(leadRule?.dailyLimit))}
							onClickToSave={value => {
								setMaxPerDay(generateCustomValueOption(Number(value)));
								leadRule.setDailyLimit(Number(value));
							}}
						/>
					) : null}
				</div>

				<Fill />

				<div className={css(styleSheet.fieldWrapper)}>
					<span className={css(styleSheet.regularText)}>Enabled:</span>
					<Toggle
						id={`${leadRule?.id}-modal-toggle`}
						isOn={leadRule?.isEnabled}
						onToggleCheckChanged={handleEnable}
						uncheckedColor={grayIconFill}
					/>
				</div>
			</Group>
			<ScheduleModal
				values={schedule}
				isOpen={scheduleModal}
				onClickToClose={handleModalClose}
				onClickToSave={handleScheduleSave}
			/>
		</div>
	);
});
