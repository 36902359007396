import * as Api from '@ViewModels';
import { animated } from '@react-spring/web';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FirstNamePlaceholder, Token } from '../../../models/Token';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultEmailSignature,
	getDisplayNameFirstNameOnly,
	replaceTokenWithActualFirstNameString,
} from '../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../models/hooks/appStateHooks';
import { EmailCampaignBrowserViewModel, ISendEmailResponse, ITemplateCard } from '../../../viewmodels/AppViewModels';
import { Button } from '../../../web/components/Button';
import { ISelectOption } from '../../../web/components/DeprecatedSelect';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { EmailComposer } from '../../../web/components/email/EmailComposer';
import { EmailPreview } from '../../../web/components/email/EmailPreview';
import { AutomationIcon } from '../../../web/components/svgs/icons/AutomationIcon';
import { calculateDraggableWindowZIndex, useDraggableWindowContext } from '../../contexts/draggableWindowContext';
import { useEmail } from '../../contexts/email';
import { useToaster } from '../../hooks';
import { BlueModal, IBlueModalProps } from '../BlueModal';
import { RecipientsList } from '../RecipientsList';
import { ComposerOptions } from './components/ComposerOptions';
import { DraggableWrapper, IDragState } from './components/DraggableWrapper';
import { ModalHeader } from './components/ModalHeader';
import { ParentAutomationViewer } from './components/ParentAutomationViewer';
import { TemplateSelector } from './components/TemplateSelector';
import { styleSheet } from './styles';

export interface ISelectedEmailRecipient {
	contact: Api.IContact;
	selectedEmailAddress?: Api.EmailAddress;
}

export interface IEmailEditorProps extends IBlueModalProps {
	className?: string;
	emailMessage: Api.EmailMessageViewModel<File>;
	onAutomationStartSuccess?: (startedAutomationId: string) => void;
	onSend?: (response: ISendEmailResponse) => void;
	selectedRecipient: ISelectedEmailRecipient;
}

const emailEditorWindowId = 'emailEditor';

export const EmailEditor: React.FC<IEmailEditorProps> = observer(
	({ className = '', emailMessage, isOpen, onAutomationStartSuccess, onRequestClose, onSend, selectedRecipient }) => {
		const email = useEmail();
		const userSession = useUserSession();
		const errorMessages = useErrorMessages();
		const toaster = useToaster();
		const templates = useRef(new Api.TemplatesViewModel(userSession)).current;
		const windowContext = useDraggableWindowContext();
		const [contact, setContact] = useState<Api.ContactViewModel>(
			selectedRecipient ? new Api.ContactViewModel(userSession, selectedRecipient.contact) : null
		);

		const [messageFieldError, setMessageFieldError] = useState<string>(null);

		const [subjectFieldError, setSubjectFieldError] = useState<string>(null);
		const [emailBodyEditorState, setEmailbodyEditorState] = useState(
			convertRawRichTextContentStateToRichContentEditorState(emailMessage?.content)
		);

		const [template, setTemplate] = useState<Api.ITemplate>(null);
		const [showingPreviewEmail, setShowingPreviewEmail] = React.useState(false);
		const [loading, setLoading] = useState(false);

		const [options, setOptions] = useState<ISelectOption<ITemplateCard>[]>([]);

		const [selectedOption, setSelectedOption] = useState<ISelectOption<ITemplateCard>>(null);
		const browser = useRef(new EmailCampaignBrowserViewModel(userSession)).current;

		const [isAddingCc, setIsAddingCc] = useState(false);
		const [ccRecipients, setCcRecipients] = useState<Api.IRecipient[]>([]);

		const [dragging, setDragging] = useState<IDragState>({ active: false });

		const [isAutomation, setAutomationState] = useState(false);

		useEffect(() => {
			windowContext.registerWindow(emailEditorWindowId);
			return () => windowContext.unregisterWindow(emailEditorWindowId);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const onEmailMessageTemplateChanged = async (t: Api.ITemplate) => {
			if (t?._type === 'AutomationTemplateReference') {
				setAutomationState(true);
				return;
			}
			setAutomationState(false);
			try {
				const fullTemplate = await templates.getById(t.id);
				setTemplate(fullTemplate);
				const emailReplaced = replaceTokenWithActualFirstNameString(
					fullTemplate.content,
					selectedRecipient?.contact?.firstName
				);
				setEmailbodyEditorState(convertRawRichTextContentStateToRichContentEditorState(emailReplaced));
				if (emailMessage) {
					emailMessage.subject = fullTemplate.subject || emailMessage.subject;
					emailMessage.setSavedAttachments(fullTemplate.attachments || []);
				}
			} catch (error) {
				toaster.push({
					message: `Unable to load template: ${(error as Api.IOperationResultNoValue)?.systemMessage}`,
					type: 'errorMessage',
				});
			}
		};

		useEffect(() => {
			setLoading(true);
			const contactToSet = selectedRecipient ? new Api.ContactViewModel(userSession, selectedRecipient.contact) : null;

			setContact(contactToSet);
			if (contactToSet && emailMessage) {
				emailMessage.contactsToAdd?.add(contactToSet);
				emailMessage.setPreferredEmailAddressForContact(contactToSet, selectedRecipient.selectedEmailAddress);
			}

			emailMessage?.setAttachments?.(new Api.AttachmentsToBeUploadedViewModel([]));
			setCcRecipients([]);
			setIsAddingCc(false);

			setMessageFieldError(null);

			setSubjectFieldError(null);
			browser

				.loadMe(userSession?.account?.additionalInfo?.industry, {
					sort: 'asc',
					sortBy: 'name',
				})
				.then(() => {
					browser.loadAutomationTemplate().then(() => {
						const automationTemplateOptions = browser?.automationTemplates?.filter(Boolean).map(t => ({
							dataContext: t,
							icon: <AutomationIcon className={css(styleSheet.automationIcon)} />,
							id: t.id,
							text: t.name,
							type: 'icon',
						}));
						const templateOptions = browser?.categorySection?.map(t => ({
							dataContext: t,
							id: t.id,
							text: t.name,
						}));
						const loadedTemplates = [...(templateOptions || []), ...(automationTemplateOptions || [])];
						setOptions(loadedTemplates);
						setSelectedOption(loadedTemplates[0]);
						onEmailMessageTemplateChanged(loadedTemplates[0]);
					});
				})
				?.catch(error => {
					errorMessages.pushApiError(error);
				})
				?.finally(() => setLoading(false));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [emailMessage]);

		React.useEffect(() => {
			if (emailMessage) {
				templates?.signatureTemplates?.reset();
				templates?.signatureTemplates?.getNext()?.then(() => {
					emailMessage.signatureTemplate = getDefaultEmailSignature(templates);
				});
			}
		}, [templates, emailMessage]);

		const onTemplateSelected = (selectedTemplate: ISelectOption<ITemplateCard>) => {
			setSelectedOption(selectedTemplate);
			onEmailMessageTemplateChanged(selectedTemplate.dataContext);
		};

		const clearErrors = () => {
			setMessageFieldError(null);

			setSubjectFieldError(null);
		};

		const onSubjectChanged = (subject: string) => {
			if (emailMessage) {
				emailMessage.subject = subject;
			}
		};
		const onCcRecipientsChange = (newCcRecipients: Api.IRecipient[]) => {
			if (emailMessage) {
				emailMessage.cc = newCcRecipients;
			}
		};
		const onSignatureTemplateChanged = (signatureTemplate: Api.ITemplate) => {
			if (emailMessage) {
				emailMessage.signatureTemplate = signatureTemplate;
			}
		};

		const onEditorStateChanged = useCallback(
			(editorState: Api.IRichContentEditorState) => {
				const emailReplaced = replaceTokenWithActualFirstNameString(
					editorState.getRawRichTextContent(),

					selectedRecipient?.contact?.firstName
				);
				setEmailbodyEditorState(convertRawRichTextContentStateToRichContentEditorState(emailReplaced));

				setTemplate(editorState.hasContent() ? template : null);
				clearErrors();
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[template]
		);

		const onRequestRemoveSavedEmailAttachment = async (savedAttachment: Api.IFileAttachment) => {
			const filteredAttachments = emailMessage?.savedAttachments?.filter(x => x.id !== savedAttachment.id);
			emailMessage?.setSavedAttachments(filteredAttachments);
		};

		const send = async () => {
			const result = await emailMessage.send()?.catch(err => errorMessages.pushApiError(err));
			if (result) {
				toaster.push({ message: 'Your email was sent successfully', type: 'successMessage' });
				email.setIsOpen(false)();

				onSend?.(result.value);
			}
		};

		const onSendEmailClick = () => {
			if (emailMessage.contactsToAdd.length === 0) {
				return;
			}
			if (!emailMessage.subject) {
				setMessageFieldError(null);
				setSubjectFieldError('Please enter a subject before sending.');
				return;
			}
			if (!emailBodyEditorState || !emailBodyEditorState?.hasContent()) {
				setMessageFieldError('Please enter a message before sending.');

				setSubjectFieldError(null);
				return;
			}
			const content = emailBodyEditorState.getRawRichTextContent();
			if (!Token.isFirstNameTokenFormattedCorrectlyLoose(content)) {
				errorMessages.push({
					messages: [
						`The first name token does not appear to be formatted properly. It should be ${FirstNamePlaceholder.symbol}.`,
					],
				});
				return;
			}
			emailMessage.content = content;
			emailMessage.options = {
				...emailMessage.options,
				sendSingleEmail: true,
			};
			send();
		};

		const onSaveAutomation = () => {
			browser

				.startAutomationForContact(selectedRecipient.contact.id, selectedOption?.dataContext.id)
				.then(response => {
					if (response) {
						toaster.push({
							message: 'Your automation was started successfully',
							type: 'successMessage',
						});
						email.setIsOpen(false)();

						onAutomationStartSuccess?.(response.value.id);
					}
				})
				.catch(error => {
					toaster.push({
						message: error.systemMessage,
						type: 'errorMessage',
					});
				});
		};

		const onCloseClick = (event?: SyntheticEvent) => {
			event?.stopPropagation();
			onRequestClose(null, true);
		};

		const bringToFront = () => windowContext.bringToFront(emailEditorWindowId);

		const handleDragChange = (dragState: IDragState) => {
			setDragging(dragState);
		};

		const displayName = getDisplayNameFirstNameOnly(contact);

		const renderAutomationPreview = () => {
			if (selectedOption?.dataContext?._type !== 'AutomationTemplateReference') {
				return null;
			}
			return (
				<ParentAutomationViewer
					selectedRecipient={selectedRecipient}
					template={selectedOption.dataContext as Api.AutomationTemplateViewModel}
				/>
			);
		};

		const emailComposerContents = (
			<animated.div
				className={`email-drag-container ${css(styleSheet.container)}`}
				style={{
					zIndex: calculateDraggableWindowZIndex(windowContext.windowIds, emailEditorWindowId),
				}}
				onClick={bringToFront}
			>
				<ModalHeader isActive={dragging.active} displayName={displayName} onClickToClose={onCloseClick} />
				<div className={`${css(styleSheet.emailEditorContainer)} ${className}`}>
					<TemplateSelector options={options} selected={selectedOption} onTemplateChange={onTemplateSelected} />
					{selectedRecipient && (
						<RecipientsList
							className={css(styleSheet.fieldContainer, styleSheet.recipientList)}
							emails={[selectedRecipient?.selectedEmailAddress]}
							onCcRecipientsChange={isAutomation ? null : onCcRecipientsChange}
							isAddingCC={isAddingCc}
							setAddCc={setIsAddingCc}
							ccRecipients={ccRecipients}
							setCcRecipients={setCcRecipients}
						/>
					)}
					<div className={css(styleSheet.editorContainer)}>
						{loading ? (
							<LoadingSpinner />
						) : isAutomation ? (
							renderAutomationPreview()
						) : (
							<EmailComposer
								advancedSettingsPopoverContent={<ComposerOptions emailMessage={emailMessage} />}
								attachments={emailMessage?.attachments}
								bodyEditorState={emailBodyEditorState}
								bodyError={messageFieldError}
								bodyTemplate={template}
								ccRecipients={emailMessage?.cc}
								className={css(styleSheet.editor)}
								hideBodyTemplateSelectorButton={true}
								individualRecipient={contact}
								onBodyEditorFocusChanged={clearErrors}
								onBodyEditorStateChanged={onEditorStateChanged}
								onBodyFieldScroll={clearErrors}
								onRequestRemoveSavedEmailAttachment={onRequestRemoveSavedEmailAttachment}
								onSignatureTemplateChanged={onSignatureTemplateChanged}
								onSubjectChanged={onSubjectChanged}
								savedAttachments={emailMessage?.savedAttachments}
								showCcField={false}
								subject={emailMessage?.subject}
								subjectError={subjectFieldError}
								templates={templates}
								signatureTemplate={emailMessage?.signatureTemplate}
							/>
						)}
						<div className={css(styleSheet.ctaContainer)}>
							<Button
								onClick={isAutomation ? onSaveAutomation : onSendEmailClick}
								label={isAutomation ? 'Start Automation' : 'Send Email'}
							/>
							{!isAutomation && (
								<Button
									onClick={() => {
										setShowingPreviewEmail(true);
									}}
									label='Preview'
									kind='reverse'
								/>
							)}
						</div>
					</div>
					{showingPreviewEmail ? (
						<BlueModal
							className='aida-email-composer-preview-modal'
							isOpen={true}
							onRequestClose={() => setShowingPreviewEmail(null)}
							useDefaultHeader={false}
						>
							<EmailPreview
								emailContent={emailBodyEditorState.getRawRichTextContent()}
								signatureTemplateId={emailMessage?.signatureTemplate?.id}
								title='Email Preview'
							/>
						</BlueModal>
					) : null}
				</div>
			</animated.div>
		);

		return (
			<DraggableWrapper isOpen={isOpen} onDragChange={handleDragChange}>
				{emailComposerContents}
			</DraggableWrapper>
		);
	}
);
