import { ISelectOption } from '../../../../../web/components/DeprecatedSelect';
import { FieldKey } from '../../../../viewModels/form';

export const reduceChildren = (accumulator: any, item: ISelectOption<FieldKey>) => {
	return item?.children ? accumulator?.concat(item?.children) : [];
};

export const filterByChecked = (item: ISelectOption<FieldKey>) => item.checked;

export const arrayOfLabels = (item: ISelectOption<FieldKey>) => item.label;

export const checkAll = (checked: boolean, defaultValues?: string[]) => (parent: ISelectOption<FieldKey>) => {
	parent.checked = checked;
	parent?.children?.map((child: ISelectOption<FieldKey>) => {
		if (defaultValues && defaultValues?.indexOf(child.name) !== -1) {
			child.checked = true;
			return child;
		}
		child.checked = checked;
		return child;
	});
	if (defaultValues && parent?.children?.filter(filterByChecked)?.length === parent?.children?.length) {
		if (parent.name !== 'all') {
			parent.checked = true;
		}
	}
	return parent;
};

export const prepareResponse = (item: any) => {
	const opt: ISelectOption<FieldKey> = {
		checked: true,
		children: item.children,
		dataContext: item.name,
		id: item.name,
		name: item.name,
		text: item.label,
	};
	return opt;
};
