import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { getDisplayName } from '../../../../../models/UiUtils';
import { AddButton } from '../../../../../web/components/AddButton';
import { Button } from '../../../../../web/components/Button';
import { DeprecatedSelect, ISelectOption } from '../../../../../web/components/DeprecatedSelect';
import { TextArea } from '../../../../../web/components/TextArea';
import { TextInput } from '../../../../../web/components/TextInput';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../../../../web/components/autocomplete/SimpleAutoCompleteSearchField';
import { styleSheet as emStyleSheet } from '../../../../../web/components/dataBoards/EditModal/styles';
import { XIcon } from '../../../../../web/components/svgs/icons/XIcon';
import { baseStyleSheet } from '../../../../../web/styles/styles';
import { BlueModal } from '../../../../components/BlueModal';
import {
	useCreateCallSuggestionMutation,
	useCreateCallSuggestionsMutation,
	useGetCallSuggestionsQuery,
} from '../../../../queries/CallSuggestion';
import {
	invalidateGetScorecardTemplates,
	useCreateScorecardTemplatePhrasesMutation,
	useUpdateEnabledUserIdsMutation,
} from '../../../../queries/ScorecardTemplate';
import { styleSheet } from './styles';

const callTrackOptions = [Api.CallTrack.Inbound, Api.CallTrack.Outbound, Api.CallTrack.Both].map(track => {
	const text = track === Api.CallTrack.Inbound ? 'Lead' : track === Api.CallTrack.Outbound ? 'Rep' : 'Both';
	return {
		dataContext: track,
		id: track,
		text,
	};
});

export const EditScorecardTemplateModal = ({
	scorecardTemplate,
	onCancel,
}: {
	scorecardTemplate: Api.IScorecardTemplate;
	onCancel: () => void;
}) => {
	const [enabledUsers, setEnabledUsers] = React.useState<Api.IUserReference[]>(scorecardTemplate.enabledUsers ?? []);
	const vertical: string = scorecardTemplate.vertical ?? '';
	const persona: Api.ScorecardPersona = scorecardTemplate.persona ?? null;
	const leadStage: Api.LeadStage = scorecardTemplate.leadStage ?? null;

	const [topics, setTopics] = React.useState<Api.IScorecardTemplatePhraseTopics[]>(() => {
		let newTopics: Api.IScorecardTemplatePhraseTopics[] = [];
		if (scorecardTemplate.currentVersion?.topics?.length) {
			newTopics = scorecardTemplate.currentVersion.topics.map(newTopic => {
				newTopic.id = newTopic.id ?? uuid();
				return newTopic;
			});
		}
		return newTopics;
	});
	const [topicsHaveChanges, setTopicsHaveChanges] = React.useState(false);
	const [enabledUsersHaveChanges, setEnabledUsersHaveChanges] = React.useState(false);

	const createScorecardTemplatePhrasesMutation = useCreateScorecardTemplatePhrasesMutation();
	const createCallSuggestionsMutation = useCreateCallSuggestionsMutation({});
	const updateEnabledUsersMutation = useUpdateEnabledUserIdsMutation();

	const callSuggestionsQuery = useGetCallSuggestionsQuery({
		vertical,
		persona,
		leadStage,
	});

	const isNew = !scorecardTemplate.id;

	const updateTopics = (newTopics: Api.IScorecardTemplatePhraseTopics[]) => {
		newTopics.forEach(newTopic => {
			newTopic.id = newTopic.id ?? uuid();
		});
		setTopics(newTopics);
		setTopicsHaveChanges(true);
	};

	const onAddTopic = () => {
		const newTopic = {};
		updateTopics([...topics, newTopic]);
	};

	const saveTopics = async () => {
		await createScorecardTemplatePhrasesMutation.mutateAsync({
			templateId: scorecardTemplate.id,
			topics,
		});

		setTopicsHaveChanges(false);
		invalidateGetScorecardTemplates();
	};

	const saveEnabledUsers = async () => {
		await updateEnabledUsersMutation.mutateAsync({
			templateId: scorecardTemplate.id,
			enabledUserIds: enabledUsers.map(x => x.id),
		});

		setEnabledUsersHaveChanges(false);
		invalidateGetScorecardTemplates();
	};

	const removeTopic = (topic: Api.IScorecardTemplatePhraseTopics) => {
		const updatedTopics = [...topics];
		const currentIndex = updatedTopics.findIndex(updatedTopic => topic.id === updatedTopic.id);
		updatedTopics.splice(currentIndex, 1);
		updateTopics(updatedTopics);
		setTopicsHaveChanges(true);
	};

	const updateTopic = (topic: Api.IScorecardTemplatePhraseTopics) => {
		const updatedTopics = [...topics];
		const currentIndex = updatedTopics.findIndex(updatedTopic => topic.id === updatedTopic.id);
		updatedTopics.splice(currentIndex, 1, topic);
		updateTopics(updatedTopics);
		setTopicsHaveChanges(true);
	};

	const createCallSuggestions = async () => {
		await createCallSuggestionsMutation.mutateAsync({ vertical, persona, leadStage });
	};

	const removeEnabledUser = (user: Api.IUserReference) => {
		const updatedEnabledUsers = [...enabledUsers];
		const currentIndex = updatedEnabledUsers.findIndex(updatedEnabledUser => user.id === updatedEnabledUser.id);
		updatedEnabledUsers.splice(currentIndex, 1);
		setEnabledUsers(updatedEnabledUsers);
		setEnabledUsersHaveChanges(true);
	};

	const onUserSelected = (e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<Api.IUser>) => {
		setEnabledUsersHaveChanges(true);
		const newEnabledUsers = [...enabledUsers, e.selection as Api.IUserReference];
		setEnabledUsers(newEnabledUsers);
		e.target.clearInput();
	};

	const callSuggestionsObj = callSuggestionsQuery.data ?? null;

	return (
		<BlueModal
			className={css(styleSheet.modalOverrides)}
			headerAccessory={
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.title)}>{isNew ? 'New Scorecard Template' : 'Edit Scorecard Template'}</div>
				</div>
			}
			isOpen
			onRequestClose={() => onCancel()}
		>
			<article className={css(styleSheet.container)}>
				<div className={css(styleSheet.formControlContainer)}>
					<div className={css(styleSheet.info)}>
						<strong className={css(emStyleSheet.label)}>Vertical: {vertical}</strong>
						<strong className={css(emStyleSheet.label)}>Persona: {persona}</strong>
						<strong className={css(emStyleSheet.label)}>Lead stage: {leadStage}</strong>
					</div>

					<div className={css(styleSheet.formControl)}>
						<label className={css(emStyleSheet.label)}>Enabled User Ids</label>

						<>
							<div>
								<SimpleAutoCompleteSearchField
									initialSearchQuery=''
									// @ts-ignore
									onItemSelected={onUserSelected}
									pageSize={5}
									placeholder='Search Users'
									resultsLimit={5}
									type={Api.ResourceAutoCompleteViewModelType.User}
								/>
							</div>
							{enabledUsers.map((user: Api.IUserReference) => {
								return (
									<div key={user.id}>
										<button onClick={() => removeEnabledUser(user)}>
											<span>
												<XIcon height={12} width={12} />
											</span>
										</button>
										<span>{getDisplayName(user)}</span>
									</div>
								);
							})}
						</>

						{enabledUsersHaveChanges ? <Button onClick={saveEnabledUsers} label='Save Enabled User Ids' /> : null}
					</div>
				</div>

				<table className={css(styleSheet.tableStyles)}>
					<thead>
						<tr>
							<th>Identifier</th>
							<th>Label</th>
							<th>Phrases</th>
							<th>Summary</th>
							<th>Target Mentions</th>
							<th>Weight</th>
							<th>Call Track</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						<>
							{topics.map(topic => {
								return (
									<EditTopicRow topic={topic} onUpdate={updateTopic} key={topic.id}>
										<button onClick={() => removeTopic(topic)}>Remove</button>
									</EditTopicRow>
								);
							})}
						</>
					</tbody>
				</table>

				{isNew ? <span>Cannot create topics until after the scorecard template is created</span> : null}

				<AddButton disabled={isNew} onClick={onAddTopic} title='Create Topic' />

				{!callSuggestionsObj ? (
					<AddButton onClick={createCallSuggestions} title='Create Suggestions for Vertical / Persona' />
				) : null}

				{topicsHaveChanges ? <Button onClick={saveTopics} label='Save Topics' /> : null}

				{callSuggestionsObj ? <CallSuggestions callSuggestionsObj={callSuggestionsObj} /> : null}
			</article>
		</BlueModal>
	);
};

const EditTopicRow = ({
	topic,
	children,
	onUpdate,
}: {
	topic: Api.IScorecardTemplatePhraseTopics;
	children: React.ReactNode;
	onUpdate: (topic: Api.IScorecardTemplatePhraseTopics) => void;
}) => {
	const [phrases, setPhrases] = React.useState<string>(topic.phrases?.join(',') ?? '');
	const [label, setLabel] = React.useState(topic.label ?? '');
	const [identifier, setIdentifier] = React.useState(topic.identifier ?? '');
	const [targetMentions, setTargetMentions] = React.useState(topic.targetMentions ?? 1);
	const [weight, setWeight] = React.useState(topic.weight ?? 0);
	const [callTrack, setCallTrack] = React.useState(topic.callTrack ?? null);
	const [summary, setSummary] = React.useState(topic.summary ?? '');

	const hasChanges = React.useMemo(() => {
		if (label && topic.label && label !== topic.label) {
			return true;
		}
		if ((summary && topic.summary && summary !== topic.summary) || (summary && !topic.summary)) {
			return true;
		}
		if (identifier && topic.identifier && identifier !== topic.identifier) {
			return true;
		}
		if (targetMentions && topic.targetMentions && targetMentions !== topic.targetMentions) {
			return true;
		}
		if (weight && topic.weight && weight !== topic.weight) {
			return true;
		}
		if (callTrack && topic.callTrack && callTrack !== topic.callTrack) {
			return true;
		}
		if (phrases && topic.phrases && phrases !== topic.phrases.join(',')) {
			return true;
		}
		if (identifier && !topic.identifier) {
			return true;
		}
		return false;
	}, [topic, phrases, label, identifier, targetMentions, weight, callTrack, summary]);

	const selectCallTrackOption = (option: ISelectOption) => {
		setCallTrack(option.dataContext);
	};

	const update = () => {
		onUpdate({
			id: topic.id,
			phrases: phrases.split(','),
			label,
			identifier,
			targetMentions,
			weight,
			callTrack,
			summary,
		});
	};

	return (
		<tr>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-topic-identifier-${topic.id}`}
					onChange={e => setIdentifier(e.target.value)}
					type='text'
					value={identifier}
					placeholder='Identifier'
				/>
			</td>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-topic-label-${topic.id}`}
					onChange={e => setLabel(e.target.value)}
					type='text'
					value={label}
					placeholder='Label'
				/>
			</td>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-topic-phrases-${topic.id}`}
					onChange={e => setPhrases(e.target.value)}
					type='text'
					value={phrases}
					placeholder='Phrases (comma separated, no spaces)'
				/>
			</td>
			<td>
				<TextArea
					inputId={`scorecard-template-modal-topic-summary-${topic.id}`}
					onChange={e => setSummary(e.target.value)}
					value={summary}
					placeholder='Summary'
				/>
			</td>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-topic-target-mentions-${topic.id}`}
					onChange={e => setTargetMentions(parseInt(e.target.value))}
					type='number'
					value={targetMentions}
					placeholder='Target mentions'
				/>
			</td>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-topic-weight-${topic.id}`}
					onChange={e => setWeight(parseFloat(e.target.value))}
					type='number'
					value={weight}
					placeholder='Weight'
				/>
			</td>
			<td>
				<DeprecatedSelect
					options={callTrackOptions}
					onOptionClick={selectCallTrackOption}
					selectedOption={callTrackOptions.find(x => x.dataContext === callTrack)}
				/>
			</td>
			<td>
				{children}
				{hasChanges ? <Button onClick={() => update()} label='Update' /> : null}
			</td>
		</tr>
	);
};

export const CallSuggestions = ({
	callSuggestionsObj,
}: {
	callSuggestionsObj: Api.ICallSuggestions;
}) => {
	const [callSuggestionsHaveChanges, setCallSuggestionsHaveChanges] = React.useState(false);
	const [callSuggestions, setCallSuggestions] = React.useState<Api.ICallSuggestion[]>(() => {
		const suggestions = callSuggestionsObj?.suggestions ?? [];
		return suggestions.map(suggestion => {
			suggestion.id = suggestion.id ?? uuid();
			return suggestion;
		});
	});

	const updateCallSuggestion = (callSuggestion: Api.ICallSuggestion) => {
		const updatedSuggestions = [...callSuggestions];
		const currentIndex = updatedSuggestions.findIndex(updatedSuggestion => callSuggestion.id === updatedSuggestion.id);
		updatedSuggestions.splice(currentIndex, 1, callSuggestion);
		updateCallSuggestions(updatedSuggestions);
		setCallSuggestionsHaveChanges(true);
	};

	const updateCallSuggestions = (newCallSuggestions: Api.ICallSuggestion[]) => {
		newCallSuggestions.forEach(newCallSuggestion => {
			newCallSuggestion.id = newCallSuggestion.id ?? uuid();
		});
		setCallSuggestions(newCallSuggestions);
		setCallSuggestionsHaveChanges(true);
	};

	const removeCallSuggestion = (callSuggestion: Api.ICallSuggestion) => {
		const updatedCallSuggestions = [...callSuggestions];
		const currentIndex = updatedCallSuggestions.findIndex(x => callSuggestion.id === x.id);
		updatedCallSuggestions.splice(currentIndex, 1);
		updateCallSuggestions(updatedCallSuggestions);
		setCallSuggestionsHaveChanges(true);
	};

	const createCallSuggestionMutation = useCreateCallSuggestionMutation();

	const onAddCallSuggestion = () => {
		const newCallSuggestion = {};
		updateCallSuggestions([...callSuggestions, newCallSuggestion]);

		setCallSuggestionsHaveChanges(true);
	};

	const saveSuggestions = async () => {
		await createCallSuggestionMutation.mutateAsync({
			id: callSuggestionsObj.id,
			suggestions: callSuggestions,
		});

		setCallSuggestionsHaveChanges(false);
		invalidateGetScorecardTemplates();
	};

	return (
		<article>
			<h5 className={css(baseStyleSheet.fontBold)}>Suggestions</h5>

			<div className={css(styleSheet.suggestionsContainer)}>
				<table className={css(styleSheet.tableStyles)}>
					<thead>
						<tr>
							<th>Label</th>
							<th>Phrases</th>
							<th>Suggestion</th>
							<th>Call Track</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						<>
							{callSuggestions.map(callSuggestion => {
								return (
									<EditCallSuggestionRow
										callSuggestion={callSuggestion}
										onUpdate={updateCallSuggestion}
										key={callSuggestion.id}
										setCallSuggestionsHaveChanges={setCallSuggestionsHaveChanges}
									>
										<button onClick={() => removeCallSuggestion(callSuggestion)}>Remove</button>
									</EditCallSuggestionRow>
								);
							})}
						</>
					</tbody>
				</table>

				{callSuggestionsHaveChanges ? <Button onClick={saveSuggestions} label='Save Suggestion Changes' /> : null}

				<AddButton onClick={onAddCallSuggestion} title='Create Suggestion' />
			</div>
		</article>
	);
};

const EditCallSuggestionRow = ({
	callSuggestion,
	children,
	onUpdate,
	setCallSuggestionsHaveChanges,
}: {
	callSuggestion: Api.ICallSuggestion;
	children: React.ReactNode;
	onUpdate: (callSuggestion: Api.ICallSuggestion) => void;
	setCallSuggestionsHaveChanges: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [phrases, setPhrases] = React.useState<string>(callSuggestion.phrases?.join(',') ?? '');
	const [label, setLabel] = React.useState(callSuggestion.label ?? '');
	const [suggestion, setSuggestion] = React.useState(callSuggestion.suggestion ?? '');
	const [callTrack, setCallTrack] = React.useState<Api.CallTrack>(callSuggestion.callTrack ?? null);

	const hasChanges = React.useMemo(() => {
		if (label && callSuggestion.label && label !== callSuggestion.label) {
			return true;
		}
		if (suggestion && callSuggestion.suggestion && suggestion !== callSuggestion.suggestion) {
			return true;
		}
		if (phrases && callSuggestion.phrases && phrases !== callSuggestion.phrases.join(',')) {
			return true;
		}
		if (callTrack && callSuggestion.callTrack && callTrack !== callSuggestion.callTrack) {
			return true;
		}
		if (!callSuggestion.label && !!label) {
			return true;
		}
		return false;
	}, [callSuggestion, phrases, label, suggestion, callTrack]);

	const selectCallTrackOption = (option: ISelectOption) => {
		setCallTrack(option.dataContext);
	};

	const update = () => {
		onUpdate({
			id: callSuggestion.id,
			phrases: phrases.split(','),
			label,
			suggestion,
			callTrack,
		});
		setCallSuggestionsHaveChanges(true);
	};

	return (
		<tr>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-callSuggestion-label-${callSuggestion.id}`}
					onChange={e => setLabel(e.target.value)}
					type='text'
					value={label}
					placeholder='Label'
				/>
			</td>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-callSuggestion-phrases-${callSuggestion.id}`}
					onChange={e => setPhrases(e.target.value)}
					type='text'
					value={phrases}
					placeholder='Phrases (comma separated, no spaces)'
				/>
			</td>
			<td>
				<TextInput
					inputId={`scorecard-template-modal-callSuggestion-suggestion-${callSuggestion.id}`}
					onChange={e => setSuggestion(e.target.value)}
					type='text'
					value={suggestion}
					placeholder='Suggestion'
				/>
			</td>
			<td>
				<DeprecatedSelect
					options={callTrackOptions}
					onOptionClick={selectCallTrackOption}
					selectedOption={callTrackOptions.find(x => x.dataContext === callTrack)}
				/>
			</td>
			<td>
				{children}
				{hasChanges ? <Button onClick={() => update()} label='Update' /> : null}
			</td>
		</tr>
	);
};
