import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import blitzPlayLightning1 from '../../assets/blitzPlayLightning1.png';
import blitzPlayLightning2 from '../../assets/blitzPlayLightning2.png';
import { useErrorMessages, useGamification } from '../../hooks';
import { TransparentButton } from '../TransparentButton';
import { Dialer } from '../TwilioDialer/Dialer';
import { AnimatedLionGraphic } from '../svgs/AnimatedLionGraphic';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

const StatusBannerBase: React.FC<IProps> = ({ className = '' }) => {
	const gamification = useGamification();
	const errorMessages = useErrorMessages();

	const userSession = useUserSession();

	const history = useHistory();

	useEffect(() => {
		if (!gamification.initScoresRetrieved) {
			gamification.getScores().catch(err => {
				errorMessages.pushApiError(err);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderDemosCount = () => {
		const demos: JSX.Element[] = [];
		for (let i = 0; i < gamification.demos; i++) {
			demos.push(<AnimatedLionGraphic key={`demo-${i}`} animate={false} className={css(styleSheet.lion)} />);
		}

		return <div className={css(styleSheet.demosContainer)}>{demos}</div>;
	};

	const dialsColor =
		gamification.percentToGoal.dials >= 100
			? styleSheet.green
			: gamification.percentToGoal.dials >= 90
				? styleSheet.yellow
				: styleSheet.red;

	const prospectingDialsColor =
		gamification.percentToGoal.prospectingDials >= 100
			? styleSheet.green
			: gamification.percentToGoal.prospectingDials >= 90
				? styleSheet.yellow
				: styleSheet.red;

	const connectionsColor =
		gamification.percentToGoal.connections >= 100
			? styleSheet.green
			: gamification.percentToGoal.connections >= 90
				? styleSheet.yellow
				: styleSheet.red;

	const followUpsSetColor =
		gamification.percentToGoal.followUpsSet >= 100
			? styleSheet.green
			: gamification.percentToGoal.followUpsSet >= 90
				? styleSheet.yellow
				: styleSheet.red;

	const coldDemoFollowUpsSetColor =
		gamification.percentToGoal.coldDemoFollowUpsSet >= 100
			? styleSheet.green
			: gamification.percentToGoal.coldDemoFollowUpsSet >= 90
				? styleSheet.yellow
				: styleSheet.red;

	const isAllowedToSelectRule = userSession?.account?.features?.aida?.allowUsersToSelectRule;

	const handleGotoStartABlitz = () => {
		history.push('/settings/startABlitz');
	};
	const isLevitateSalesCoffeeAccount = userSession.account.isLevitateSalesCoffeeAccount;

	const coldDemoFollowUpsSet = gamification.mDailyActivity?.score?.coldDemoFollowUpsSet || 0;
	const regularFollowUpsSet = gamification.followUpsSet - coldDemoFollowUpsSet;

	return (
		<div className={`${css(styleSheet.statusBannerContainer)} ${className}`}>
			<div className={`${css(styleSheet.statusBannerContent)}`}>
				<div style={{ width: 330 }}>
					<Dialer />
				</div>
				<div className={css(styleSheet.statsContainer)}>
					<div className={css(styleSheet.statContainer)}>
						<div className={css(styleSheet.stat, prospectingDialsColor)}>{gamification.prospectingDials}</div>
						<div className={css(styleSheet.label)}>Prospecting Dials</div>
					</div>
					<div className={css(styleSheet.statContainer)}>
						<div className={css(styleSheet.stat, dialsColor)}>{gamification.dials}</div>
						<div className={css(styleSheet.label)}>Dials</div>
					</div>
					<div className={css(styleSheet.statContainer)}>
						<div className={css(styleSheet.stat, connectionsColor)}>{gamification.connections}</div>
						<div className={css(styleSheet.label)}>Connections</div>
					</div>
					{gamification.goals?.followUpsSet > 0 ? (
						<div className={css(styleSheet.statContainer)}>
							<div className={css(styleSheet.stat, followUpsSetColor)}>{gamification.followUpsSet}</div>
							<div className={css(styleSheet.label)}>Follow Ups</div>
						</div>
					) : null}
					{isLevitateSalesCoffeeAccount ? (
						<div className={css(styleSheet.statContainer)}>
							<div className={css(styleSheet.stat, coldDemoFollowUpsSetColor)}>{regularFollowUpsSet}</div>
							<div className={css(styleSheet.label)}>Reg Follow Ups</div>
						</div>
					) : null}
					{gamification.demos > 0 && (
						<div className={css(styleSheet.statContainer)}>
							<div className={css(styleSheet.stat)}>{renderDemosCount()}</div>
							<div className={css(styleSheet.label)}>Demos</div>
						</div>
					)}
					{isAllowedToSelectRule && (
						<div className={css(styleSheet.startABlitzContainer)}>
							<TransparentButton className={css(styleSheet.startABlitzButton)} onClick={handleGotoStartABlitz}>
								<img
									className={css(styleSheet.blitzPlayLightning1)}
									src={blitzPlayLightning1}
									alt='Blitz Play Lightning'
								/>
								<img
									className={css(styleSheet.blitzPlayLightning2)}
									src={blitzPlayLightning2}
									alt='Blitz Play Lightning'
								/>
								Start a Blitz
							</TransparentButton>
						</div>
					)}
				</div>
				<div style={{ width: 330 }}>&nbsp;</div>
			</div>
		</div>
	);
};

export const StatusBanner = observer(StatusBannerBase);
