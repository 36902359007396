import { Call, Device, TwilioError } from '@twilio/voice-sdk';
import * as Api from '@ViewModels';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import {
	DialerClientType,
	IPhoneCall,
	IPhoneNumber,
	IRemoteEvent,
	ITelephonyConfiguration,
	PhoneCallDirection,
	asApiError,
} from '../../extViewmodels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { useErrorMessages } from '../hooks';
import { AppState } from '../models/AppState';
import { ApiClient } from '../services/api';
import { useTwilioDialer } from '../services/twilioDialer';
import {
	ICallerdId,
	IPhoneCallRemoteResourceEvent,
	PhoneCallStatus,
	PhoneCallViewModel,
	PhoneCallsViewModel,
} from '../viewModels/phonecall';
import { LeadServedSource } from '../viewModels/queue';
import { IWebsocketSubscription } from '../viewModels/websockets';

export enum DialerPhoneCallStatus {
	NoCall,
	Dialing,
	Ringing,
	Connected,
}

export interface ITelephonyContext {
	dialerState?: Device.State;
	phoneCalls: PhoneCallsViewModel; // eventually stop using it

	initiateDialer: () => Promise<boolean>;
	initiateCall: (phoneNumber?: IPhoneNumber, companyId?: string, contactId?: string) => Promise<boolean>;
	acceptCall: () => void;
	rejectCall: () => void;
	endCall: () => Promise<boolean>;

	setTelephonyConfiguration: (configuration?: ITelephonyConfiguration) => any;
	telephonyConfiguration?: ITelephonyConfiguration;
	lastPhoneCall?: IPhoneCall;
	phoneCall?: IPhoneCall;
	setActivePhoneCall: (phoneCall: IPhoneCall) => void;
	phoneCallStatus: DialerPhoneCallStatus;
	isWebDialerEnabled: boolean;
	isPendingOutcome: boolean;
	setIsPendingOutcome: (value: boolean) => any;
	isMuted: boolean;
	setIsMuted: (value: boolean) => any;

	sendDigits: (digits: string) => void;

	callerId?: ICallerdId;
	call?: Call;
}

const TelephonyContext = createContext<ITelephonyContext>(null);

export const useTelephony = () => useContext<ITelephonyContext>(TelephonyContext);

interface IProps {
	children?: ReactNode;
}

export const TelephonyContextProvider: FC<IProps> = ({ children }) => {
	const { phoneCalls, websockets } = AppState;
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();

	const [telephonyConfiguration, setTelephonyConfiguration] = useState<ITelephonyConfiguration>(
		userSession.telephonyConfiguration
	);

	const [phoneCallStatus, setPhoneCallStatus] = useState<DialerPhoneCallStatus>(DialerPhoneCallStatus.NoCall);
	const [phoneCall, setPhoneCall] = useState<IPhoneCall>(undefined);
	const [lastPhoneCall, setLastPhoneCall] = useState<IPhoneCall>(null);
	const [callerId, setCallerId] = useState<ICallerdId>(undefined);
	const [isPendingOutcome, setIsPendingOutcome] = useState<boolean>(undefined);
	const [isWebDialerEnabled, setIsWebDialerEnabled] = useState<boolean>(undefined);

	const onError = (twilioError: TwilioError.TwilioError) => {
		console.error('Twilio Error', twilioError.message);
	};

	const setActivePhoneCall = (newPhoneCall: IPhoneCall) => {
		console.log('settting active phone call', newPhoneCall);
		setPhoneCall(newPhoneCall);
		setLastPhoneCall(null);
	};

	const onIncomingCall = async (phoneCallId: string) => {
		console.log('onIncomingCall', phoneCallId);

		const client = new ApiClient(userSession);
		const result = await client.getPhoneCallById(phoneCallId);

		if (!result.success) {
			throw asApiError(result);
		}

		setPhoneCall(result.value);
		setPhoneCallStatus(DialerPhoneCallStatus.Ringing);
	};

	const twilioDialer = useTwilioDialer({ onError, onIncomingCall });

	const initiateDialer = async () => {
		if (telephonyConfiguration?.provider !== Api.TelephonyServiceProvider.Twilio) {
			return;
		}

		const device = await twilioDialer.init();
		return !!device;
	};

	const initiateCall = async (phoneNumber?: IPhoneNumber, companyId?: string, contactId?: string) => {
		if (!phoneNumber?.metadata?.e164) {
			// ...
			return false;
		}

		if (telephonyConfiguration?.provider === Api.TelephonyServiceProvider.Twilio && !twilioDialer?.device) {
			initiateDialer();
			return;
		}

		try {
			if (phoneCallStatus === DialerPhoneCallStatus.Dialing || !!phoneCall) {
				return;
			}

			setPhoneCallStatus(DialerPhoneCallStatus.Dialing);

			const client = new ApiClient(userSession);

			// right now we assume web client
			const phoneCallResult = await client.createPhoneCall({
				clientType: DialerClientType.Web,

				companyId,
				contactIds: contactId ? [contactId] : undefined,
				phoneNumber: phoneNumber.metadata.e164,
				preferredTelephonyConfigurationId: telephonyConfiguration?.id,
			});

			if (!phoneCallResult.success) {
				throw phoneCallResult;
			}

			setActivePhoneCall(phoneCallResult.value);

			if (telephonyConfiguration?.provider === Api.TelephonyServiceProvider.Twilio) {
				const customProperties = {
					companyId,
					contactId,
					phoneCallId: phoneCallResult.value.id,
					telephonyConfigurationId: telephonyConfiguration.id,
				};

				if (!(await twilioDialer.dial(phoneNumber.metadata.e164, customProperties))) {
					console.error('failed to start twilio call');
					// TODO: auto log outcome "eror"
					// ...
					setPhoneCallStatus(DialerPhoneCallStatus.NoCall);
					return false;
				}
			} else {
				phoneCalls.setExistingCall(phoneCallResult.value, phoneCallResult.value.companyId);
				setIsPendingOutcome(true);
			}

			return true;
		} catch (err) {
			errorMessages.pushApiError(err);
			setPhoneCallStatus(DialerPhoneCallStatus.NoCall);
			return false;
		}
	};

	const acceptCall = () => {
		const { companyId } = phoneCall || {};

		if (twilioDialer.acceptCall()) {
			setPhoneCallStatus(DialerPhoneCallStatus.Connected);

			if (companyId) {
				const { origin, pathname } = location;
				location.href = `${origin + pathname}#/queue?from=${LeadServedSource.IncomingCall}&companyId=${companyId}`;
				phoneCalls.setExistingCall(phoneCall, phoneCall.companyId);
			} else {
				phoneCalls.clearCurrentCall();
			}
		}
	};

	const clearCall = () => {
		if (phoneCall) {
			setLastPhoneCall(phoneCall);
		}
		setPhoneCall(undefined);
	};

	const rejectCall = () => {
		if (twilioDialer.rejectCall()) {
			clearCall();
		}
	};

	const endCall = async () => {
		twilioDialer.hangUp();
		clearCall();

		return true;
	};

	const onPhoneCallEvent = async (events: IRemoteEvent<IPhoneCallRemoteResourceEvent>[]) => {
		for (const event of events) {
			const { calling, id, ringing, clientType, connected, hangup } = event.value;

			if (clientType === 'Web') {
				continue;
			}

			if (ringing) {
				const call = phoneCalls.incomingCalls.find(incomingCall => incomingCall.id === id);

				if (call) {
					call.updateStatus(event.value);
					if (call.status === PhoneCallStatus.Connected) {
						// an incoming call was answered
						phoneCalls.currentCall = call;
					}

					if (call.status === PhoneCallStatus.Hangup) {
						phoneCalls.currentCall = call;
						phoneCalls.incomingCalls = phoneCalls.incomingCalls.filter(incomingCall => incomingCall.id !== call.id);
					}
				} else if (!calling && !connected && !hangup) {
					// is a new incoming call
					const client = new ApiClient(userSession);
					const result = await client.getPhoneCallById(id);

					if (result.success) {
						phoneCalls.incomingCalls = [...phoneCalls.incomingCalls, new PhoneCallViewModel(userSession, result.value)];
					} else {
						throw asApiError(result);
					}
				}
			} else if (calling) {
				if (phoneCalls.currentCall) {
					if (phoneCalls.currentCall.id === id) {
						phoneCalls.currentCall.updateStatus(event.value);
					} else {
						// event is for a different outbound call
					}
				} else {
					//  no current call found
				}
			}
		}
	};

	const subscribeToWebSockets = () => {
		if (!websockets) {
			return;
		}

		const phoneCallSubscription: IWebsocketSubscription = {
			callback: onPhoneCallEvent,
			events: ['PhoneCallEvent'],
			name: 'phonecalls',
		};

		websockets.subscribe(phoneCallSubscription);
	};

	const unsubscribeToWebSockets = () => {
		websockets?.unsubscribe('phonecalls');
	};

	useEffect(() => {
		setIsWebDialerEnabled(!!twilioDialer?.device);
	}, [twilioDialer?.device]);

	useEffect(() => {
		setCallerId(undefined);

		if (!phoneCall) {
			// no call, update status
			setPhoneCallStatus(DialerPhoneCallStatus.NoCall);
		} else if (phoneCall.direction === PhoneCallDirection.Inbound && !!phoneCall.companyId) {
			const client = new ApiClient(userSession);
			client.getLead(phoneCall.companyId).then(result => {
				if (result.success && !!result.value.company.id && !!result.value.company.companyName) {
					setCallerId({
						companyId: result.value.company.id,
						companyName: result.value.company.companyName,
						phoneNumber: phoneCall.phoneNumber?.e164,
					});
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCall]);

	useEffect(() => {
		if (phoneCalls?.currentCall && phoneCalls?.currentCall.id !== 'manual') {
			setIsPendingOutcome(true);
		}

		if (!phoneCalls?.currentCall) {
			if (!twilioDialer?.call) {
				clearCall();
			}

			setIsPendingOutcome(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCalls?.currentCall]);

	useEffect(() => {
		if (!twilioDialer) {
			return;
		}

		// update call status
		if (twilioDialer.isConnected) {
			setPhoneCallStatus(DialerPhoneCallStatus.Connected);
			if (phoneCall?.companyId) {
				phoneCalls.setExistingCall(phoneCall, phoneCall.companyId);
			} else {
				phoneCalls.clearCurrentCall();
			}
		} else {
			clearCall();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [twilioDialer?.isConnected]);

	useEffect(() => {
		if (!twilioDialer) {
			return;
		}

		// update call status
		if (twilioDialer.isRinging) {
			setPhoneCallStatus(DialerPhoneCallStatus.Ringing);
		} else {
			if (phoneCallStatus === DialerPhoneCallStatus.Ringing) {
				setPhoneCallStatus(DialerPhoneCallStatus.NoCall);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [twilioDialer?.isRinging]);

	useEffect(() => {
		// mount
		subscribeToWebSockets();

		setTelephonyConfiguration(userSession?.telephonyConfiguration);

		return () => {
			// unmount
			unsubscribeToWebSockets();

			setTelephonyConfiguration(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSession.telephonyConfiguration]);

	return (
		<TelephonyContext.Provider
			value={{
				acceptCall,
				callerId,
				endCall,
				initiateCall,
				initiateDialer,
				dialerState: twilioDialer.deviceState,
				isMuted: twilioDialer.isMuted,
				isPendingOutcome,
				isWebDialerEnabled,
				phoneCall,
				lastPhoneCall,
				setActivePhoneCall,
				phoneCalls,
				phoneCallStatus,
				rejectCall,
				sendDigits: twilioDialer.sendDigits,
				setIsMuted: twilioDialer.setIsMuted,
				setIsPendingOutcome,
				setTelephonyConfiguration,
				telephonyConfiguration,
				call: twilioDialer?.call,
			}}
		>
			{children}
		</TelephonyContext.Provider>
	);
};
