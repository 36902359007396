import * as React from 'react';
import { useEffect, useState } from 'react';
import { ApiModelType } from '../../../../extViewmodels';
import { ISelectOption } from '../../../../web/components/DeprecatedSelect';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { IIntegrationPropertyConfig } from '../../../viewModels/leads/interfaces';
import { useIntegrationProperties } from '../IntegrationPropertiesContext';
import { GeneralSettingsItem } from './GeneralSettingsItem';
import { sortPropertyItemsByKey } from './LeadGeneralSettings';

interface IProps {
	description: string;
	disabled?: boolean;
	filterBy: ApiModelType;
	onFetchStatusChange: (value: FetchStatus) => void;
	propertyKey: keyof IIntegrationPropertyConfig;
}

export const PropertyMappingSelect: React.FC<IProps> = ({
	description,
	disabled,
	filterBy,
	onFetchStatusChange,
	propertyKey,
}) => {
	const { config, status, update } = useIntegrationProperties();

	const [selected, setSelected] = useState<ISelectOption>();

	const propertyName = config?.[propertyKey];

	useEffect(() => {
		onFetchStatusChange(status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const listItems: any[] = filterBy === 'DealPipeline' ? config?.pipelines : config?.properties;

	useEffect(() => {
		if (status === FetchStatus.Done) {
			const formattedSelected = listItems
				?.filter((i: any) => (i.name || i.id) === propertyName)
				.map((i: any) => ({
					dataContext: i.label,
					id: i.name || i.id,
					text: i.label,
				}))?.[0];
			setSelected(formattedSelected || null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propertyName, status]);

	useEffect(() => {
		if (disabled === true) {
			setSelected(null);
		}
	}, [disabled]);

	const handleSettingSelection = (option: ISelectOption) => {
		update({ [propertyKey]: option.id });
	};

	const formattedOptions = sortPropertyItemsByKey(listItems, 'label')
		.filter(item => (item.fieldType || item._type) === filterBy)
		.map(item => {
			return {
				dataContext: item.label,
				id: item.name || item.id,
				text: item.label,
			};
		});

	if (formattedOptions.length === 0) {
		return null;
	}

	return (
		<GeneralSettingsItem<string>
			defaultSelected={selected}
			description={description}
			disabled={disabled}
			onSelect={handleSettingSelection}
			selectOptions={formattedOptions}
		/>
	);
};
