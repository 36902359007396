import { css } from 'aphrodite';
import * as React from 'react';
import { ReactNode } from 'react';
import { styleSheet } from '../styles';

interface IProps {
	color?: string;
	label?: string | ReactNode;
	value: number | string | ReactNode;
	width?: number;
}

export const ListColumn: React.FC<IProps> = ({ color, label, value, width }) => {
	return (
		<div className={css(styleSheet.leaderColumn)} style={{ color, width }}>
			<span>{value}</span>
			{label && <span className={css(styleSheet.columnLabel)}>{label}</span>}
		</div>
	);
};
