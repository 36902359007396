import * as React from 'react';
import { FC, createContext, useEffect, useState } from 'react';
import { useLambda } from '../../models/hooks/useLambda';
import { SearchModal } from '../components/SearchModal';

export enum SearchKeyOptions {
	CallFromUnknown = 'call-from-unknown',
}

export interface ISearchPayload {
	key: SearchKeyOptions;
}
export interface ISearchContext {
	payload: ISearchPayload;
	setPayload: (payload: ISearchPayload) => void;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
	setShowSearchLambda: (newValue: boolean) => () => void;
	showSearch: boolean;
}

export const SearchContext = createContext<ISearchContext>(null);

export const SearchProvider: FC = ({ children }) => {
	const [showSearch, setShowSearch, setShowSearchLambda] = useLambda(false);
	const [searchQuery, setSearchQuery] = useLambda('');
	const [payload, setPayload] = useState(null);

	useEffect(() => {
		if (searchQuery) {
			setShowSearch(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const onRequestClose = () => {
		setShowSearch(false);
		setSearchQuery('');
		setPayload(null);
	};

	return (
		<SearchContext.Provider
			value={{
				payload,
				setPayload,
				setSearchQuery,
				setShowSearch,
				setShowSearchLambda,
				showSearch,
			}}
		>
			{children}
			<SearchModal initialQuery={searchQuery} isOpen={showSearch} onRequestClose={onRequestClose} payload={payload} />
		</SearchContext.Provider>
	);
};

export const useSearch = () => {
	return React.useContext<ISearchContext>(SearchContext);
};
