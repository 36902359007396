import * as Api from '@ViewModels';
import { StyleDeclarationValue } from 'aphrodite';
import { createContext, useContext, useEffect, useState } from 'react';
import { getDisplayName } from '../../../models/UiUtils';
import { AppState } from '../../models/AppState';
import { useGetScorecardTemplatesQuery } from '../../queries';
import { IRealtimeTranscriptionUtteranceEvent } from '../../viewModels/phonecall';
import { IWebsocketSubscription } from '../../viewModels/websockets';

export const TwilioContext = createContext(null);

export interface IKeywordSuggestion {
	title: string;
	message: string;
	score: number;
}

export interface ISalesAiSentiment {
	score: number;
	color: StyleDeclarationValue;
	keyword: string;
}

export interface ISalesAiSentimentEvent {
	id: string;
	score: number;
	color: StyleDeclarationValue;
	keyword: string;
}

export const TwilioContextProvider: React.FC = ({ children }) => {
	const { websockets } = AppState;
	const [suggestions, setSuggestions] = useState<IKeywordSuggestion[]>([]);
	const [completeTopicIdentifiers, setCompleteTopicIdentifiers] = useState<string[]>([]);
	const [incompleteTopicIdentifiers, setIncompleteTopicIdentifiers] = useState<string[]>([]);
	const [realtimeTranscriptionUtterances, setRealtimeTranscriptionUtterances] = useState<string[]>([]);

	const scorecardTemplatesQuery = useGetScorecardTemplatesQuery({});

	const scorecardHandler = (events: Api.IRemoteEvent<Api.IScorecardEvent>[]) => {
		const lastEvent = events[events.length - 1].value;

		setCompleteTopicIdentifiers(lastEvent.completeTopicIdentifiers);
		setIncompleteTopicIdentifiers(lastEvent.incompleteTopicIdentifiers);
	};

	const realtimeTranscriptionUtteranceHintHandler = (
		events: Api.IRemoteEvent<IRealtimeTranscriptionUtteranceEvent>[]
	) => {
		const lastEvent = events[events.length - 1].value;

		setRealtimeTranscriptionUtterances(prev => {
			return [...prev, `${lastEvent.speaker === 'inbound_track' ? 'Lead' : 'SDR'}: ${lastEvent.transcription}`];
		});
	};

	const scorecardTemplates: Api.IScorecardTemplate[] = (scorecardTemplatesQuery.data?.values ??
		[]) as Api.IScorecardTemplate[];

	const startNewRealtimeTranscriptionUtterance = (phoneCall: Api.IPhoneCall) => {
		const vertical = scorecardTemplates.find(x => x.id === phoneCall.activeScorecard.scorecardTemplateId)?.vertical;
		const sdrName = getDisplayName(phoneCall.creator);

		setRealtimeTranscriptionUtterances([`Call started between ${sdrName} and a lead in the ${vertical} vertical.`]);
	};

	const clearRealtimeTranscriptionUtterances = () => {
		setRealtimeTranscriptionUtterances([]);
	};

	useEffect(() => {
		const scorecardSubscription: IWebsocketSubscription = {
			callback: scorecardHandler,
			events: ['ScorecardEvent'],
			name: 'scorecardEvent',
		};

		const realtimeTranscriptionUtteranceHint: IWebsocketSubscription = {
			callback: realtimeTranscriptionUtteranceHintHandler,
			events: ['RealtimeTranscriptionUtteranceEvent'],
			name: 'realtimeTranscriptionUtteranceEvent',
		};

		websockets.subscribe(scorecardSubscription);
		websockets.subscribe(realtimeTranscriptionUtteranceHint);

		return () => {
			websockets.unsubscribe('scorecardEvent');
			websockets.unsubscribe('realtimeTranscriptionUtteranceEvent');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const context = {
		suggestions,
		setSuggestions,
		completeTopicIdentifiers,
		setCompleteTopicIdentifiers,
		incompleteTopicIdentifiers,
		setIncompleteTopicIdentifiers,
		realtimeTranscriptionUtterances,
		setRealtimeTranscriptionUtterances,
		clearRealtimeTranscriptionUtterances,
		startNewRealtimeTranscriptionUtterance,
	};
	return <TwilioContext.Provider value={context}>{children}</TwilioContext.Provider>;
};

export const useTwilio = () => {
	const context = useContext(TwilioContext);

	if (!context) {
		throw new Error('Cannot use Twilio without context');
	}

	return context;
};
