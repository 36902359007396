import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DeprecatedSelect, ISelectOption } from '../../../../web/components/DeprecatedSelect';
import { EditItemPenIcon } from '../../../../web/components/svgs/icons/EditItemPenIcon';
import { brandPrimaryLight } from '../../../styles/colors';
import { LeadRuleViewModel, RecurrenceType, TurnThisOnOptions } from '../../../viewModels/leadrules';
import { IScheduleInterval, ScheduleModal } from '../../LeadRuleModal/components/ScheduleModal';
import { prepareFromWeeklySchedule, prepareToWeeklySchedule, turnThisOnOptions } from '../../LeadRuleModal/utils';
import { TransparentButton } from '../../TransparentButton';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';

interface IProps {
	leadRule: LeadRuleViewModel;
}

export const RuleContainerHeader: React.FC<IProps> = observer(({ leadRule }) => {
	const defaultTurnThisOn = turnThisOnOptions[0];
	const [selectedTurnThisOn, setTurnThisOn] = useState<ISelectOption<number>>(defaultTurnThisOn);
	const [scheduleModal, setScheduleModal] = useState(false);
	const [schedule, setSchedule] = useState(null);

	const secondaryTurnThisOn = turnThisOnOptions[1];

	useEffect(() => {
		if (leadRule?.recurrence === RecurrenceType.AlwaysOn) {
			setTurnThisOn(defaultTurnThisOn);
		}
		if (leadRule?.recurrence === RecurrenceType.SingleInterval) {
			setTurnThisOn(defaultTurnThisOn);
		}
		if (leadRule?.recurrence === RecurrenceType.Weekly) {
			setTurnThisOn(secondaryTurnThisOn);
			const prepared = [...(leadRule?.weeklySchedule ?? [])].map(prepareFromWeeklySchedule);

			setSchedule(prepared);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadRule]);

	useEffect(() => {
		if (!schedule) {
			setTurnThisOn(defaultTurnThisOn);
		} else {
			setTurnThisOn(secondaryTurnThisOn);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schedule]);

	const handleTurnThisOn = (option: ISelectOption<number>) => {
		setTurnThisOn(option);
		if (option.id === TurnThisOnOptions.Scheduled) {
			setScheduleModal(true);
		} else {
			leadRule?.setRecurrence(RecurrenceType.AlwaysOn);
		}
	};

	const handleModalClose = () => {
		setScheduleModal(false);
		if (!schedule) {
			setTurnThisOn(defaultTurnThisOn);
		}
	};

	const handleScheduleSave = (savedSchedule: IScheduleInterval[]) => {
		setSchedule(savedSchedule);
		setScheduleModal(false);
		const prepared = [...savedSchedule].map(prepareToWeeklySchedule);
		leadRule?.setRecurrence(RecurrenceType.Weekly);

		leadRule?.setWeeklySchedule(prepared);
	};

	const handleEditSchedule = () => setScheduleModal(true);

	const isScheduled = selectedTurnThisOn?.id === TurnThisOnOptions.Scheduled;

	return (
		<div className={css(styleSheet.ruleContainerHeader)}>
			<Group vCentered={true}>
				<>
					<div className={css(styleSheet.regularText)}>Turn this on:</div>
					<DeprecatedSelect
						styles={[styleSheet.turnThisOnSelect]}
						onOptionClick={handleTurnThisOn}
						options={turnThisOnOptions}
						selectedOption={selectedTurnThisOn}
					/>
				</>
				{schedule && isScheduled && (
					<TransparentButton onClick={handleEditSchedule}>
						<Group spacingSize={5}>
							<EditItemPenIcon fillColor={brandPrimaryLight} />
							<div className={css(styleSheet.regularText, styleSheet.disabled)}>Edit schedule</div>
						</Group>
					</TransparentButton>
				)}
			</Group>
			<ScheduleModal
				values={schedule}
				isOpen={scheduleModal}
				onClickToClose={handleModalClose}
				onClickToSave={handleScheduleSave}
			/>
		</div>
	);
});
