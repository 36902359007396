import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ContactViewModel, EmailAddress, IContact } from '../../../extViewmodels';
import {
	copyToClipboard,
	getDisplayNamePreferFirstNameOverEmail,
	getPartialFormattedPhoneNumber,
} from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useModal } from '../../../models/hooks/useModal';
import { Avatar } from '../../../web/components/Avatar';
import { DefaultDeleteConfirmationOptions } from '../../../web/components/ConfirmationDialog';
import { DeprecatedSelect, ISelectOption } from '../../../web/components/DeprecatedSelect';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { MoreIcon } from '../../../web/components/svgs/icons/MoreIcon';
import { WarningIcon } from '../../../web/components/svgs/icons/WarningIcon';
import { baseStyleSheet } from '../../../web/styles/styles';
import { useEmail } from '../../contexts/email';
import { useTelephony } from '../../contexts/telephony';
import { useErrorMessages, useToaster } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { BlueConfirmationDialog } from '../BlueConfirmationDialog';
import { ContactInfo } from '../ContactInfo';
import { ExternalLink } from '../ExternalLink';
import { NoneFound } from '../NoneFound';
import { TransparentButton } from '../TransparentButton';
import { SmallCallButton, SmallDirectLineButton } from '../TwilioDialer/CallButton';
import { CopyIcon } from '../svgs/icons/CopyIcon';
import { styleSheet } from './styles';

enum MoreOptions {
	Delete = 'delete',
	Edit = 'edit',
	MarkAsDM = 'markAsDM',
	UnmarkAsDM = 'unmarkAsDM',
}

interface IProps {
	className?: string;
	contacts?: ContactViewModel[];
	newContactIsOpen: boolean;
	onClickToEdit: () => void;
	onSave: (updated: IContact, original: IContact) => void;
}

const CompanyContactsBase: React.FC<IProps> = ({
	className = '',
	contacts = [],
	newContactIsOpen,
	onClickToEdit,
	onSave,
}) => {
	const queue = useQueue();
	const { isPendingOutcome } = useTelephony();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const emailEditor = useEmail();
	const userSession = useUserSession();
	const [contactToDelete, setContactToDelete] = useState<ContactViewModel>(null);
	const [contactToToggleAsDM, setContactToToggleAsD] = useState<ContactViewModel>(null);

	const [editableId, setEditableId] = useState<string>(null);

	const deleteContact = useCallback(() => {
		queue.lead
			.deleteContact(contactToDelete.id)
			.then(() => {
				toaster.push({
					message: 'Contact deleted successfully.',
					type: 'successMessage',
				});
			})
			.catch(err => {
				errorMessages.pushApiError(err);
			})
			.finally(() => {
				setContactToDelete(null);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactToDelete]);

	const deleteConfirmationModal = useModal(
		false,
		() => {
			deleteContact();

			return () => {
				setContactToDelete(null);
			};
		},
		[deleteContact]
	);

	useEffect(() => {
		if (newContactIsOpen) {
			setEditableId(null);
		}
	}, [newContactIsOpen]);

	useEffect(() => {
		deleteConfirmationModal.setIsOpen(!!contactToDelete)();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactToDelete]);

	useEffect(() => {
		if (contactToToggleAsDM) {
			queue.lead
				.toggleDecisionMaker(contactToToggleAsDM)
				.then(() => {
					toaster.push({
						message: 'Contact updated successfully.',
						type: 'successMessage',
					});
				})
				.catch(err => {
					errorMessages.pushApiError(err);
				})
				.finally(() => {
					setContactToToggleAsD(null);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactToToggleAsDM]);

	const getMoreOptions = useCallback(
		(contact: ContactViewModel): ISelectOption<MoreOptions>[] => {
			const options: ISelectOption<MoreOptions>[] = [
				{
					dataContext: MoreOptions.Edit,
					id: 'contact-more-options-edit',
					text: 'Edit',
					type: 'blue',
				},
			];

			if (queue.lead.isDecisionMaker(contact)) {
				options.push({
					dataContext: MoreOptions.UnmarkAsDM,
					id: 'contact-more-options-unmark-as-dm',
					text: 'Unmark as decision maker',
					type: 'blue',
				});
			} else {
				options.push({
					dataContext: MoreOptions.MarkAsDM,
					id: 'contact-more-options-mark-as-dm',
					text: 'Mark as decision maker',
					type: 'blue',
				});
			}

			options.push({
				dataContext: MoreOptions.Delete,
				id: 'contact-more-options-delete',
				text: 'Delete',
				type: 'blue',
			});

			return options;
		},
		[queue.lead]
	);

	const onEmailClick = (email: EmailAddress, contact: ContactViewModel) => () => {
		emailEditor.setSelectedEmailRecipient({
			contact: contact.toJs(),
			selectedEmailAddress: email,
		});
		emailEditor.setIsOpen(true)();
	};

	const onMoreOptionClick = (contact: ContactViewModel) => (option: ISelectOption<MoreOptions>) => {
		switch (option.dataContext) {
			case MoreOptions.Delete:
				setContactToDelete(contact);
				break;
			case MoreOptions.Edit:
				setEditableId(contact?.id);
				onClickToEdit();
				break;
			case MoreOptions.MarkAsDM:
			case MoreOptions.UnmarkAsDM:
				setContactToToggleAsD(contact);
				break;
			default:
				break;
		}
	};

	const onGoogleClick = (c: ContactViewModel) => () => {
		if (!c) {
			return;
		}

		const url = encodeURI(
			`https://google.com/search?q=${c.firstName}+${c.lastName}+${c.phoneNumbers.map(
				p => p.metadata?.standard || '' + '+'
			)} ${c.primaryEmail?.value || ''}+${queue.lead?.company?.webSite || ''}`
		);
		window.open(url, '_blank');
	};

	const renderContacts = () => {
		return [...contacts]
			.sort(c => (queue.lead.isDecisionMaker(c) ? -1 : 1))
			.map(c => {
				if (editableId === c.id) {
					const handleCancel = () => setEditableId(null);
					const handleSave = (updated: IContact) => onSave(updated, c.toJs());
					return (
						<ContactInfo
							contactViewModel={c}
							isDecisionMaker={queue?.lead?.isDecisionMakerById(c?.id)}
							onCancel={handleCancel}
							onSave={handleSave}
							key={c.id}
						/>
					);
				}
				return (
					<div key={c.id} className={css(styleSheet.contactCard)}>
						<div className={css(styleSheet.moreMenuContainer)}>
							<div className={css(styleSheet.dmFlag)}>
								{contactToToggleAsDM?.id === c.id ? (
									<LoadingSpinner className={css(aidaBaseStyleSheet.absoluteCenter)} type='tiny' />
								) : queue.lead.isDecisionMaker(c) ? (
									'DM'
								) : null}
							</div>
							<DeprecatedSelect
								onOptionClick={onMoreOptionClick(c)}
								options={getMoreOptions(c)}
								selectedOptionTitle={<MoreIcon fillColor='' />}
								showCaret={false}
								styles={[styleSheet.moreMenu]}
								triggerStyles={[styleSheet.moreMenuTrigger]}
							/>
						</div>
						<div className={css(styleSheet.avatarContainer)}>
							<ExternalLink className={css(styleSheet.googleLink)} onClick={onGoogleClick(c)} />
							<Avatar className={css(styleSheet.avatar)} entity={c} />
						</div>
						<div className={css(styleSheet.infoContainer)}>
							<div>
								<div className={css(styleSheet.name)}>{getDisplayNamePreferFirstNameOverEmail(c)}</div>
								{!!c.jobTitle && <div className={css(styleSheet.jobTitle)}>{c.jobTitle}</div>}
							</div>
							<div>
								{renderPhoneNumbers(c)}
								{renderEmails(c)}
							</div>
							{!userSession.account.isLevitateSalesCoffeeAccount && !!c.bio && (
								<div className={css(styleSheet.bio)}>{c.bio}</div>
							)}
						</div>
					</div>
				);
			});
	};

	const renderEmails = (c: ContactViewModel) => {
		if (!c?.emailAddresses?.length) {
			return null;
		}

		if (c.unsubscribeMetadata?.date) {
			return <span className={css(styleSheet.emailUnsubcribed)}>Unsubscribed from emails</span>;
		}

		const emails = c.emailAddresses?.map((e, i) => {
			const copyEmail = (evt: React.MouseEvent<HTMLSpanElement>) => {
				evt.stopPropagation();
				const success = copyToClipboard(e.value);
				if (success) {
					toaster.push({
						message: `Email copied to clipboard`,
						type: 'successMessage',
					});
				} else {
					toaster.push({
						message: `Email failed to copy to clipboard`,
						type: 'errorMessage',
					});
				}
			};

			return (
				<TransparentButton className={css(styleSheet.clickableItem)} key={i} onClick={onEmailClick(e, c)}>
					<span>{e.value}</span>
					<span className={css(styleSheet.copyIcon)} onClick={copyEmail}>
						<CopyIcon />
					</span>
				</TransparentButton>
			);
		});

		return <div className={css(styleSheet.clickableItemsContainer)}>{emails}</div>;
	};

	const renderPhoneNumbers = (c: ContactViewModel) => {
		if (!c?.phoneNumbers?.length) {
			return null;
		}

		const phoneNumbers = c.phoneNumbers.map((phoneNumber, i) => {
			const copyPhoneNumber = (evt: React.MouseEvent<HTMLSpanElement>) => {
				evt.stopPropagation();
				const success = copyToClipboard(phoneNumber?.metadata?.standard);
				if (success) {
					toaster.push({
						message: `Phone number copied to clipboard`,
						type: 'successMessage',
					});
				} else {
					toaster.push({
						message: `Phone number failed to copy to clipboard`,
						type: 'errorMessage',
					});
				}
			};

			const isInternational =
				phoneNumber.metadata?.countryCode && phoneNumber.metadata.countryCode !== 1 && !!phoneNumber.metadata.standard;

			// need to remove any ext that exists in the standard format
			// to meet design requirements
			const displayPhoneNumber = isInternational
				? phoneNumber.metadata.standard
				: getPartialFormattedPhoneNumber(phoneNumber.metadata?.standard?.split('ext')[0].trim());

			const isDirectLine = phoneNumber.label?.toLowerCase() === 'direct';

			return (
				<div key={i} className={css(styleSheet.phoneNumberItem)}>
					<div className={css(baseStyleSheet.flexCenter)}>
						{!!phoneNumber.label && phoneNumber.label.trim().length > 0 && (
							<span className={css(styleSheet.label)}>{phoneNumber.label.trim()}:&nbsp;</span>
						)}
						<div>
							<span>{displayPhoneNumber}</span>
							{!!phoneNumber.metadata?.extension && !isInternational && (
								<span> ext {phoneNumber.metadata?.extension}</span>
							)}
						</div>
						<span className={css(styleSheet.copyPhoneIcon)} onClick={copyPhoneNumber}>
							<CopyIcon />
						</span>
					</div>
					{isDirectLine ? (
						<SmallDirectLineButton
							phoneNumber={phoneNumber}
							companyId={queue.lead.company.id}
							contactId={c.id}
							isDisabled={isPendingOutcome}
						/>
					) : (
						<SmallCallButton
							phoneNumber={phoneNumber}
							companyId={queue.lead.company.id}
							contactId={c.id}
							isDisabled={isPendingOutcome}
						/>
					)}
				</div>
			);
		});

		return <div className={css(styleSheet.clickableItemsContainer)}>{phoneNumbers}</div>;
	};

	if (!contacts?.length) {
		return <NoneFound text='No contacts found.' />;
	}

	return (
		<div className={`${css(styleSheet.companyContactsContainer)} ${className}`}>
			{renderContacts()}
			<BlueConfirmationDialog
				icon={<WarningIcon />}
				modalProps={deleteConfirmationModal}
				options={DefaultDeleteConfirmationOptions}
				title='Delete Contact?'
			/>
		</div>
	);
};

export const CompanyContacts = observer(CompanyContactsBase);
