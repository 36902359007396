import { CSSProperties, StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../web/styles/styles';
import {
	actionItemTintColor,
	brandPrimary,
	brandPrimaryDark,
	brandPrimaryHover,
	brandSecondary,
	destructive,
	green,
	orange,
	white,
	yellow,
} from '../../styles/colors';
import { aidaBaseStyles, headerHeight } from '../../styles/styles';

const shake = {
	'0%': {
		color: yellow,
		transform: 'rotate(0deg)',
		transformOrigin: 'center',
	},
	'100%': {
		color: yellow,
		transform: 'rotate(0deg)',
		transformOrigin: 'center',
	},
	'25%': {
		color: white,
		transform: 'rotate(5deg)',
		transformOrigin: 'center',
	},
	'50%': {
		color: yellow,
		transform: 'rotate(0eg)',
		transformOrigin: 'center',
	},
	'75%': {
		color: white,
		transform: 'rotate(-5deg)',
		transformOrigin: 'center',
	},
};

export const styleSheet = StyleSheet.create({
	callCta: {
		':active': {
			boxShadow: 'inset 0 0 5px 1px rgba(0, 0, 0, .18)',
			cursor: 'pointer',
			transform: 'translate3d(0,0,0)',
		},
		':disabled': {
			opacity: 0.5,
		},
		':hover': {
			boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
			cursor: 'pointer',
			transform: 'translate3d(0,-1px,0)',
		},
		...aidaBaseStyles.flexCenter,
		background: orange,
		border: 'none',
		borderRadius: 3,
		color: '#fff',
		fontSize: '14px',
		minWidth: '100%',
		padding: 10,
		textDecoration: 'none',
		transition: 'box-shadow 0.1s, transform 0.1s',
		...CssSelectors.allChildren(
			{
				...(aidaBaseStyles.flexHorizontalCenter as CSSProperties),
				...CssSelectors.allChildren(
					{
						marginRight: 10,
					},
					'svg'
				),
			},
			'span'
		),
	},
	directLineCta: {
		':active': {
			boxShadow: 'inset 0 0 5px 1px rgba(0, 0, 0, .18)',
			cursor: 'pointer',
			transform: 'translate3d(0,0,0)',
		},
		':disabled': {
			opacity: 0.5,
		},
		':hover': {
			boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
			cursor: 'pointer',
			transform: 'translate3d(0,-1px,0)',
		},
		...aidaBaseStyles.flexCenter,
		background: green,
		border: 'none',
		borderRadius: 3,
		color: '#fff',
		fontSize: '14px',
		minWidth: '100%',
		padding: 10,
		textDecoration: 'none',
		transition: 'box-shadow 0.1s, transform 0.1s',
		...CssSelectors.allChildren(
			{
				...(aidaBaseStyles.flexHorizontalCenter as CSSProperties),
				...CssSelectors.allChildren(
					{
						marginRight: 10,
					},
					'svg'
				),
			},
			'span'
		),
	},
	dialerCallAction: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 28,
		display: 'flex',
		flexShrink: 0,
		height: 28,
		justifyContent: 'center',
		marginLeft: 5,
		width: 28,
		...CssSelectors.allDescendants(
			{
				fill: brandPrimary,
				height: 18,
				width: 18,
			},
			'svg'
		),
	},
	dialerCallActionConnected: {
		background: actionItemTintColor,
		...CssSelectors.allDescendants(
			{
				fill: white,
			},
			'svg'
		),
	},
	dialerCallActionDisconnected: {
		background: destructive,
		...CssSelectors.allDescendants(
			{
				fill: white,
			},
			'svg'
		),
	},
	dialerCallActionMute: {
		background: brandPrimary,
		height: 28,
		width: 28,
		...CssSelectors.allDescendants(
			{
				fill: white,
				height: 18,
				width: 18,
			},
			'svg'
		),
	},
	dialerCallActionUnmute: {
		background: orange,
		height: 28,
		width: 28,
		...CssSelectors.allDescendants(
			{
				fill: white,
				height: 18,
				width: 18,
			},
			'svg'
		),
	},
	dialerCallIncomingTitle: {
		animationDuration: '0.2s',
		animationIterationCount: 'infinite',
		animationName: shake,
		color: yellow,
		display: 'inline-block',
		fontSize: 12,
		fontWeight: 600,
	},
	dialerCallInfo: {
		flex: 1,
		marginRight: 10,
	},
	dialerCallInfoSubText: {
		color: white,
		fontSize: 11,
		marginTop: 2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	dialerCallInfoText: {
		color: white,
		fontSize: 12,
		fontWeight: 600,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	dialerContainer: {
		alignItems: 'center',
		backgroundColor: brandPrimaryHover,
		borderRadius: 4,
		display: 'flex',
		height: 48,
		padding: 10,
		width: '100%',
	},
	dialerEnableButton: {
		color: 'yellow',
		cursor: 'pointer',
		textAlign: 'center',
		width: '100%',
	},
	dialerPlaceholder: {
		color: brandPrimaryDark,
		flex: 1,
		fontSize: 14,
		fontWeight: 600,
		textAlign: 'center',
	},
	dialerStatus: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
	},
	dialerStatusDot: {
		borderRadius: '50%',
		height: 10,
		marginRight: 5,
		paddingTop: 2,
		width: 10,
	},
	dialerStatusDotRegistered: {
		background: green,
	},
	dialerStatusDotBad: {
		background: destructive,
	},
	dialerStatusDotRegistering: {
		background: orange,
	},
	dialerTimer: {
		color: brandSecondary,
		fontSize: 14,
		marginRight: 5,
	},
	dialpadContainer: {
		background: brandSecondary,
		borderRadius: 4,
		display: 'flex',
		flexWrap: 'wrap',
		height: 240,
		justifyContent: 'center',
		marginTop: 5,
		width: 190,
	},
	dialpadInfo: {
		alignItems: 'center',
		appearance: 'none',
		background: 'none',
		border: 'none',
		color: brandPrimary,
		direction: 'rtl',
		display: 'flex',
		fontSize: 22,
		fontWeight: 600,
		height: 50,
		justifyContent: 'center',
		letterSpacing: 3,
		outline: 'none',
		paddingLeft: 15,
		paddingRight: 15,
		textAlign: 'center',
		width: 190,
	},
	dialpadKey: {
		'::hover': {
			color: brandPrimary,
		},
		alignItems: 'center',
		color: white,
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 18,
		fontWeight: 600,
		height: 50,
		transition: 'all .2s ease',
		width: 60,
		...CssSelectors.allDescendants(
			{
				fontSize: 10,
				fontWeight: 400,
				marginTop: -4,
			},
			'small'
		),
	},
	dialpadTrigger: {
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: 28,
		display: 'flex',
		flexShrink: 0,
		height: 28,
		justifyContent: 'center',
		marginLeft: 5,
		width: 28,
		...CssSelectors.allDescendants(
			{
				fill: white,
			},
			'path'
		),
	},
	dialpadTriggerActive: {
		...CssSelectors.allDescendants({ fill: yellow }, '#key1'),
		...CssSelectors.allDescendants({ fill: yellow }, '#key2'),
		...CssSelectors.allDescendants({ fill: yellow }, '#key3'),
		...CssSelectors.allDescendants({ fill: yellow }, '#key4'),
		...CssSelectors.allDescendants({ fill: yellow }, '#key5'),
		...CssSelectors.allDescendants({ fill: yellow }, '#key8'),
	},
	disabled: {
		opacity: 0.5,
	},
	headerContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandPrimary,
		height: headerHeight,
		left: 0,
		padding: '0 30px',
		position: 'fixed',
		right: 0,
		top: 0,
		zIndex: 1,
	},
	smallCallButton: {
		':active': {
			boxShadow: 'inset 0 0 5px 1px rgba(0, 0, 0, .18)',
			cursor: 'pointer',
			transform: 'translate3d(0,0,0)',
		},
		':disabled': {
			opacity: 0.5,
		},
		':hover': {
			boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
			cursor: 'pointer',
			transform: 'translate3d(0,-1px,0)',
		},
		background: orange,
		borderRadius: 3,
		color: white,
		fontSize: '12px',
		padding: '4px 8px',
		textAlign: 'center',
		textDecoration: 'none',
	},
	splashScreen: {
		alignItems: 'center',
		backgroundColor: '#002942',
		display: 'flex',
		flexDirection: 'column',
		height: `calc(100vh - ${headerHeight}px)`,
		justifyContent: 'center',
		left: 0,
		paddingBottom: 105,
		position: 'absolute',
		top: headerHeight,
		width: '100%',
		zIndex: 9999999999,
	},
	splashScreenImage: {
		width: 480,
	},
	splashScreenLogo: {
		height: 50,
		width: 'auto',
	},
	smallDirectLineButton: {
		':active': {
			boxShadow: 'inset 0 0 5px 1px rgba(0, 0, 0, .18)',
			cursor: 'pointer',
			transform: 'translate3d(0,0,0)',
		},
		':disabled': {
			opacity: 0.5,
		},
		':hover': {
			boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
			cursor: 'pointer',
			transform: 'translate3d(0,-1px,0)',
		},
		background: green,
		borderRadius: 3,
		color: white,
		fontSize: '12px',
		padding: '4px 8px',
		textAlign: 'center',
		textDecoration: 'none',
	},
});
