import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useGamification } from '../../hooks';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

const CoffeeProgressBarBase: React.FC<IProps> = ({ className = '' }) => {
	const gamification = useGamification();
	const userSession = useUserSession();

	if (!gamification.goals) {
		return null;
	}

	let metricLabel = 'PTS';
	let currentDayMetric = gamification.points;
	let currentDayPercentageMetric = gamification.userPercentage.points;
	let currentDayGoalMetric = gamification.goals?.points;
	let currentDayGoalPercentageMetric = gamification.goalPercentage.points;
	let currentDayMetricPercentToGoal = gamification.percentToGoal.points;

	// Ref: https://github.com/Real-Magic/Levitate/issues/11970
	// Show progress based on dials, not points for our sales team.
	if (userSession.account.isLevitateSalesCoffeeAccount) {
		metricLabel = 'DIALS';
		// Use prospecting dials instead of total dials (Ref: https://github.com/Real-Magic/Levitate/issues/13203)
		currentDayMetric = gamification.prospectingDials;
		currentDayGoalMetric = gamification.goals?.prospectingDials;
		currentDayPercentageMetric = gamification.userPercentage.prospectingDials;
		currentDayGoalPercentageMetric = gamification.goalPercentage.prospectingDials;
		currentDayMetricPercentToGoal = gamification.percentToGoal.prospectingDials;
	}

	const barColor =
		currentDayMetricPercentToGoal >= 100
			? styleSheet.progressGreen
			: currentDayMetricPercentToGoal >= 90
				? styleSheet.progressYellow
				: styleSheet.progressRed;

	return (
		<div className={`${css(styleSheet.pointsProgressBarContainer)} ${className}`}>
			<div className={css(styleSheet.progressBar)}>
				<div
					className={css(styleSheet.progress, barColor)}
					style={{
						width: `${Math.min(currentDayPercentageMetric, 100)}%`,
					}}
				/>
			</div>
			<div
				className={css(styleSheet.marker)}
				style={{
					left: `${Math.min(currentDayGoalPercentageMetric / 100, 1) * 400}px`,
				}}
			>
				<div className={css(styleSheet.markerText)}>You should be here by {moment().format('h:mma')}</div>
			</div>
			<div className={css(styleSheet.pointsContainer)}>
				<span>
					{currentDayMetric || '0'} / {currentDayGoalMetric || '000'}
				</span>
				<span>{metricLabel}</span>
			</div>
		</div>
	);
};

export const CoffeeProgressBar = observer(CoffeeProgressBarBase);
