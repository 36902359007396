import * as Api from '@ViewModels';
import { Button } from '@WebComponents/Button';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Noop, flatten, getDisplayName } from '../../../extViewmodels/Utils';
import { Topics } from '../../../models/LocalNotificationTopics';
import { postNotification } from '../../../models/LocalNotifications';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ISelectOption, MultiSelect } from '../../../web/components/DeprecatedSelect';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../../web/components/MoreMenu';
import { ContactAutomationsProgressBanner } from '../../../web/components/contacts/ContactAutomationsProgressBanner';
import { EntityTimelineBare } from '../../../web/components/entities/EntityTimeline';
import { DefaultCompactEditorConfig } from '../../../web/components/notes/NoteEditor';
import { RichContentDocumentEditor } from '../../../web/components/richContent/RichContentDocumentEditor';
import { ClockIcon } from '../../../web/components/svgs/icons/ClockIcon';
import { EditItemPenIcon } from '../../../web/components/svgs/icons/EditItemPenIcon';
import { ITimelineCriteria } from '../../../web/containers/People/Contact/Activity';
import { useAudio } from '../../contexts/audioPlayer';
import { useDealModal } from '../../contexts/dealModal';
import { useQueueLeadNote } from '../../contexts/queueLeadNote';
import { useToaster, useWebsockets } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { LeadViewModel } from '../../viewModels/leads/lead';
import { AidaNoteViewModel } from '../../viewModels/note';
import { IWebsocketSubscription } from '../../viewModels/websockets';
import { AidaActionItem } from '../AidaActionItem';
import { Fab, IFabMenuItem } from '../Fab';
import { HexButton } from '../HexButton';
import { BookedGraphic } from '../svgs/BookedGraphic';
import { HookRejectedGraphic } from '../svgs/HookRejectedGraphic';
import { QualifiedPitchGraphic } from '../svgs/QualifiedPitchGraphic';
import { QuickPitchGraphic } from '../svgs/QuickPitchGraphic';
import { StumpedGraphic } from '../svgs/StumpedGraphic';
import { DollarSignIcon } from '../svgs/icons/DollarSignIcon';
import { PlayIcon } from '../svgs/icons/audio/PlayIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	lead: LeadViewModel;
	onFabMenuItemClick: (menuItem: IFabMenuItem) => void;
	onFollowUpMenuEditClicked?(followUp: Api.FollowUpEventViewModel): void;
}

const typesStorageKey = 'aidaTimelineFilterOptions';

const fabMenuItems: IFabMenuItem[] = [
	{
		dataContext: 'CreateDeal',
		icon: <DollarSignIcon />,
		tooltip: 'Create a Deal',
	},
	{
		dataContext: 'UpdateDeal',
		icon: <DollarSignIcon />,
		tooltip: 'Update Deal',
	},
	{
		dataContext: 'AddNote',
		icon: <EditItemPenIcon fillColor='#fff' />,
		tooltip: 'Add Note',
	},
	{
		dataContext: 'SetFollowUp',
		icon: <ClockIcon fillColor='#fff' />,
		tooltip: 'Set Follow Up',
	},
];

const supportedActions: Api.TimelineEventTypes[] = [
	'SentEmailEvent',
	'NoteEvent',
	'PhoneCallEvent',
	'AutomationEvent',
	'AutomationCompletedEvent',
	'ReceivedEmailEvent',
	'ViewedEmailEvent',
	'RepliedEmailEvent',
	'ContactArchivedEvent',
	'DealCreatedEvent',
	'DealUpdatedEvent',
	'PhoneCallCompletedEvent',
	'UntrackedPhoneCallEvent',
	'FollowUpEvent',
	'CancelledFollowUpEvent',
	'RescheduledFollowUpEvent',
	// - Removed to avoid bias for subsequent serving 'SkipLeadEvent',
];

export const engagementTypeOptions: ISelectOption<ITimelineCriteria>[] = [
	{
		dataContext: {
			name: 'all',
			type: 'All',
		},
		forceSelectAll: true,
		id: 'rich-content-filter-option-show-all',
		text: 'Show all',
		type: 'checkbox',
	},
	{
		dataContext: {
			name: 'notes',
			type: 'NoteEvent',
		},
		id: 'rich-content-filter-option-show-notes',
		text: 'Show notes',
		type: 'checkbox',
	},
	{
		dataContext: {
			name: 'emails',
			type: 'SentEmailEvent',
		},
		id: 'rich-content-filter-option-show-emails',
		text: 'Show emails',
		type: 'checkbox',
	},
	{
		dataContext: {
			name: 'meetings',
			type: 'MeetingEvent',
		},
		id: 'rich-content-filter-option-show-meetings',
		text: 'Show meetings',
		type: 'checkbox',
	},
	{
		dataContext: {
			name: 'deals',
			type: ['DealCreatedEvent', 'DealUpdatedEvent'],
		},
		id: 'rich-content-filter-option-show-deals',
		text: 'Show deals',
		type: 'checkbox',
	},
	{
		dataContext: {
			name: 'phone calls',
			type: ['PhoneCallEvent', 'PhoneCallCompletedEvent', 'UntrackedPhoneCallEvent'],
		},
		id: 'rich-content-filter-option-show-phone-calls-completed',
		text: 'Show phone calls',
		type: 'checkbox',
	},
	{
		dataContext: {
			name: 'follow ups',
			type: ['FollowUpEvent', 'CancelledFollowUpEvent', 'RescheduledFollowUpEvent'],
		},
		id: 'rich-content-filter-option-show-follow-ups',
		text: 'Show follow ups',
		type: 'checkbox',
	},
	/*
	Removed for avoid bias by reps for subsequent serving of this lead. If we bring this back, we'll want an accont preference in features.aida to control this.
	{
		dataContext: {
			name: 'skips',
			type: 'SkipLeadEvent',
		},
		id: 'rich-content-filter-option-skip-leads',
		text: 'Show skips',
		type: 'checkbox',
	},*/
];

const allEvents = engagementTypeOptions.filter(x => x.dataContext.type !== 'All').map(x => x.dataContext.type);

const allEventNames = flatten(allEvents).filter(x => x !== 'All') as Api.TimelineEventTypes[];

const renderContentFor: Api.TimelineEventTypes[] = ['ActionItemEvent', 'PhoneCallCompletedEvent'];

const loadRichContentSupportedTypesFromLocalStorage = () => {
	const storedTypes = window.localStorage.getItem(typesStorageKey)?.split('|');
	return !storedTypes || storedTypes.length === 0 || storedTypes.includes('All')
		? allEventNames
		: storedTypes.filter(
				option => option !== 'All' && option !== 'ConversationThreadEvent' && option !== 'ActionItemEvent'
			);
};

const TimelineBase: React.FC<IProps> = ({ className = '', onFabMenuItemClick, onFollowUpMenuEditClicked }) => {
	const userSession = useUserSession();
	const queue = useQueue();
	const websockets = useWebsockets();
	const toaster = useToaster();
	const websocketSubscriptionName = useRef(`timeline-${queue.lead?.company?.id}`).current;
	const { setDefaultNote } = useQueueLeadNote();
	const timeline = useRef(new Api.EntityTimelineViewModel(queue.lead?.company)).current;
	const [hasFetchedFirstPage, setHasFetchedFirstPage] = useState(false);
	const [timelineEventTypes, setTimelineEventTypes] = useState<(Api.TimelineEventTypes | 'All')[]>(
		loadRichContentSupportedTypesFromLocalStorage() as Api.TimelineEventTypes[]
	);
	const [currentFabMenuItems, setCurrentFabMenuItems] = useState(fabMenuItems);
	const audioContext = useAudio();

	const dealModal = useDealModal();

	const setTypes = (types: string[]) => {
		setTimelineEventTypes(types as Api.TimelineEventTypes[]);
		localStorage.setItem(typesStorageKey, types.join('|').replace(',', '|'));
	};

	useEffect(() => {
		// fetch 1 item (including all types) for considering last interaction

		timeline.controller.getNext(null, 1, { typeOf: allEventNames }).then(() => setHasFetchedFirstPage(true));

		// subscribe to websockets for timeline updates
		const subscription: IWebsocketSubscription = {
			callback: () => {
				postNotification({ topic: Topics.TIMELINE_REFRESH });
			},
			events: ['TimelineEvent'],
			name: websocketSubscriptionName,
		};

		websockets.subscribe(subscription);

		return () => websockets.unsubscribe(websocketSubscriptionName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (queue.lead?.lastDealIsOpen) {
			setCurrentFabMenuItems(fabMenuItems.filter(x => x.dataContext !== 'CreateDeal'));
		} else {
			setCurrentFabMenuItems(fabMenuItems.filter(x => x.dataContext !== 'UpdateDeal'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	useEffect(() => {
		setHasFetchedFirstPage(queue.lead?.company.richContentViewModel?.controller?.hasFetchedFirstPage);
	}, [queue.lead?.company.richContentViewModel?.controller?.hasFetchedFirstPage]);

	const getSelectedOptions = (): ISelectOption<ITimelineCriteria>[] => {
		const storedOptions = timelineEventTypes;
		const everyOptionSelected =
			(timelineEventTypes.filter(x => x !== 'All') as Api.TimelineEventTypes[]).every(x => allEventNames.includes(x)) &&
			timelineEventTypes.length === allEventNames.length;
		if (timelineEventTypes.includes('All') || everyOptionSelected) {
			return engagementTypeOptions;
		}

		return engagementTypeOptions.filter(option => {
			if (!storedOptions || storedOptions.length === 0) {
				return option;
			}

			if (Array.isArray(option.dataContext.type)) {
				return option.dataContext.type.some(x => storedOptions.includes(x));
			}

			return storedOptions.includes(option.dataContext.type);
		});
	};

	const getTitle = (selectedOptions: ISelectOption<ITimelineCriteria>[]) => {
		if (selectedOptions.find(option => option.dataContext.type === 'All')) {
			return <div>Show all</div>;
		}

		const maxCharCount = 25;
		const text = `Show ${selectedOptions.map(option => option.dataContext.name).join(' and ')}`;
		return <div>{text.length > maxCharCount ? `${text.slice(0, maxCharCount)}...` : text}</div>;
	};

	const lastInteraction = useMemo(() => {
		const timelineList = timeline.controller?.fetchResults?.toArray();

		if (!timelineList?.length) {
			return 'None';
		}

		const recent = moment(timelineList[0].timestamp);
		const now = moment();

		const diffMins = now.diff(recent, 'minutes');
		if (diffMins < 5) {
			return 'Just now';
		}

		let diff = now.diff(recent, 'hours');
		if (diff < 1) {
			return 'Less than an hour ago';
		} else if (diff < 24) {
			return `${diff} hour${diff > 1 ? 's' : ''} ago`;
		}

		diff = now.diff(recent, 'days');
		if (diff < 7) {
			return `${diff} days ago`;
		}

		diff = now.diff(recent, 'weeks');
		if (diff < 4) {
			return `${diff} week${diff > 1 ? 's' : ''} ago`;
		}

		diff = now.diff(recent, 'months');
		if (diff < 12) {
			return `${diff} month${diff > 1 ? 's' : ''} ago`;
		}

		diff = now.diff(recent, 'years');
		return `About ${diff} year${diff > 1 ? 's' : ''} ago`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFetchedFirstPage]);

	const onFollowUpMenuItemClicked = (followUp: Api.FollowUpEventViewModel) => (menuItem: 'edit' | 'delete') => {
		if (menuItem === 'edit') {
			onFollowUpMenuEditClicked(followUp);
		} else if (menuItem === 'delete') {
			queue
				.deleteFollowUp()

				.then(() => toaster.push({ message: 'Follow up was removed', type: 'successMessage' }))
				.catch((err: Api.IOperationResultNoValue) =>
					toaster.push({ message: `Unable to remove follow up: ${err.systemMessage}`, type: 'errorMessage' })
				);
		}
	};

	const onRichContentFilterClose = (selectedOptions: ISelectOption<ITimelineCriteria>[]) => {
		const selected = selectedOptions.map(option => option.dataContext.type);
		setTypes(flatten(selected));
	};
	const handleEditNote = (richContent: Api.RichContentViewModel) => {
		if (richContent instanceof Api.NoteViewModel) {
			setDefaultNote(new AidaNoteViewModel(userSession, richContent.toJs()));
		}
	};

	const renderAction = (event: Api.TimelineEventViewModel<Api.ITimelineEvent>) => {
		const dealHasSameId = queue.lead?.lastDeal?.id === event.resourceId;
		const dealIdExists = queue.lead?.lastDeal?.id !== null;
		const isDealEvent = event.type === 'DealUpdatedEvent' || event.type === 'DealCreatedEvent';

		if (isDealEvent && dealHasSameId && dealIdExists) {
			const onClick = () => {
				queue.lead?.loadDealForm(queue.lead?.lastDeal?.id);
				dealModal.setShowDealModal(true);
			};
			return <Button label='Edit Deal' size='small' onClick={onClick} />;
		}

		if (event.type === 'NoteEvent') {
			const richContent = (event as Api.NoteEventViewModel).viewmodel;
			if (richContent.canEdit) {
				return (
					<MoreMenu
						menuButtonClassName={`${
							richContent?.context?.source === Api.RichContentContextSource.EmailMessageSend && css(styleSheet.moreMenu)
						} entity-notes-list-header-more-menu`}
					>
						<MoreMenuItem onClick={() => handleEditNote(richContent)}>Edit</MoreMenuItem>
					</MoreMenu>
				);
			}
		}

		if (event.type.includes('FollowUp')) {
			const followUp = event as Api.FollowUpEventViewModel;
			if (event.type !== 'CancelledFollowUpEvent') {
				return (
					<MoreMenu menuButtonClassName={`${css(styleSheet.moreMenu)} entity-notes-list-header-more-menu`}>
						<MoreMenuItem onClick={() => onFollowUpMenuItemClicked(followUp)('edit')}>Edit follow up</MoreMenuItem>
						<MoreMenuItem onClick={() => onFollowUpMenuItemClicked(followUp)('delete')}>Delete follow up</MoreMenuItem>
					</MoreMenu>
				);
			}
		}

		return null;
	};

	const renderContent = useCallback((event: Api.TimelineEventViewModel<Api.ITimelineEvent>) => {
		switch (event.type) {
			case 'ActionItemEvent': {
				return <AidaActionItem actionItem={(event as Api.ActionItemEventViewModel).viewmodel} />;
			}
			case 'PhoneCallCompletedEvent': {
				const phoneCallEvent = event as Api.PhoneCallCompletedEventViewModel;
				const phoneCall = phoneCallEvent.phoneCall;

				const onClick = () => {
					audioContext.setIsOpen(true)();

					audioContext.setAudioSrc(phoneCall.recording?.publicUrl);

					audioContext.setTitle(`Call Recording (${moment(phoneCall.creationDate).format('M/DD/YYYY [at] h:mma')})`);

					audioContext.setSubtitle(`${getDisplayName(phoneCall.creator)} called ${phoneCall.phoneNumber?.standard}`);

					audioContext.setPhoneCallId(phoneCall.id);
				};
				return (
					<>
						{phoneCallEvent.viewmodel.rawContentState && (
							<RichContentDocumentEditor
								autoFocus={false}
								className='note-editor-body-editor'
								config={DefaultCompactEditorConfig}
								contentState={convertRawRichTextContentStateToRichContentEditorState(
									phoneCallEvent.viewmodel.rawContentState
								)}
								readOnly={true}
							/>
						)}
						{!!phoneCall?.recording?.publicUrl && (
							<div className={css(styleSheet.recordingLink)} onClick={onClick}>
								<PlayIcon className={css(styleSheet.playIcon)} />
								Play Recording
							</div>
						)}
					</>
				);

				return null;
			}
			default: {
				break;
			}
		}

		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const callIcon = useMemo(() => {
		const type = timeline.controller.fetchResults
			.reverse()
			.find(
				x => x.type === 'PhoneCallCompletedEvent' || x.type === 'DealCreatedEvent' || x.type === 'DealUpdatedEvent'
			);
		let graphic = null;
		if (!type) {
			return graphic;
		}

		const kind = type.title.match(/\([\w\s!]*\)\s*$/)?.[0];
		switch (kind) {
			case '(Demo Booked!)':
			case '(Demo Rescheduled)':
				graphic = <BookedGraphic />;
				break;
			case '(Quick Pitch)':
				graphic = <QuickPitchGraphic />;
				break;
			case '(Qualified Pitch)':
				graphic = <QualifiedPitchGraphic />;
				break;
			case '(Stumped By Objection)':
				graphic = <StumpedGraphic />;
				break;
			case '(Hook Rejected)':
				graphic = <HookRejectedGraphic />;
				break;
			default:
				return null;
		}
		return {
			display: <HexButton className={css(styleSheet.hexButton)} graphic={graphic} onClick={Noop} text='' />,
			text: kind.replace('(', '').replace(')', '').replace('!', ''),
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeline?.controller?.fetchResults?.length]);

	const contacts = queue.lead.contacts;

	return (
		<div className={`${css(styleSheet.timelineContainer)} ${className}`}>
			{contacts &&
				contacts?.map(contact => {
					if (contact?.inProgressAutomations?.length <= 0) {
						return null;
					}
					return (
						<div className={css(styleSheet.automationBanner)} key={contact.id}>
							<ContactAutomationsProgressBanner
								contact={contact}
								customTitle={`Automation in Progress for ${contact.firstName}`}
							/>
						</div>
					);
				})}

			<div className={css(styleSheet.timelineHeader)}>
				<div className={css(styleSheet.lastInteractionContainer)}>
					<span className={css(styleSheet.lastContact)}>
						Last
						<br />
						Contact
					</span>
					<span>{callIcon?.display}</span>
					<div>
						<div>{lastInteraction}</div>
						<div className={css(styleSheet.lastInteractionTime)}>{callIcon?.text}</div>
					</div>
				</div>
				<div className={css(styleSheet.engagementsTypesSelectContainer)}>
					<MultiSelect
						onClose={onRichContentFilterClose}
						options={engagementTypeOptions}
						selectedOptions={getSelectedOptions()}
						selectAllIfNoneSelected={true}
						selectedOptionsTitle={getTitle}
						styles={[styleSheet.engagementsTypesSelect]}
						triggerStyles={[styleSheet.engagementTypesSelectTrigger]}
					/>
				</div>
				<Fab className={css(styleSheet.fab)} menuItems={currentFabMenuItems} onMenuItemClick={onFabMenuItemClick} />
			</div>
			{!queue.lead ? (
				<LoadingSpinner className={css(styleSheet.loadingSpinner)} type='large' />
			) : (
				<EntityTimelineBare
					className={css(styleSheet.timeline)}
					entity={queue.lead.company}
					idsToExclude={[queue.lead.executiveSummary.id]}
					renderAction={renderAction}
					renderActionFor={supportedActions}
					renderContent={renderContent}
					renderContentFor={renderContentFor}
					placeholderText={"You haven't had any interactions yet"}
					// casting because 'all' is not included in the else case (typescript just can't tell that)
					eventTypes={
						timelineEventTypes.some(x => x === 'All') ? allEventNames : (timelineEventTypes as Api.TimelineEventTypes[])
					}
					userSession={userSession}
				/>
			)}
		</div>
	);
};

export const Timeline = observer(TimelineBase);
