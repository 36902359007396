export enum SettingsCardType {
	Blitzes = 'blitzes',
	Categories = 'categories',
	DashAndReports = 'dashAndReports',
	EmailSignature = 'emailSignature',
	EmailTemplates = 'emailTemplates',
	Gamification = 'gamification',
	Goals = 'goals',
	ManageUsers = 'manageUsers',
	Password = 'password',
	PhoneNumbers = 'phoneNumbers',
	PrizesLog = 'prizesLog',
	SalesAi = 'salesAi',
	SlotMachinePrizes = 'slotMachinePrizes',
	SlotMachines = 'slotMachines',
	StartABlitz = 'startABlitz',
	TranscriptionTest = 'transcriptionTest',
	Utilities = 'utilities',
	Voicemail = 'voicemail',
}

export interface ISettingsCard {
	href?: string; // external link
	settingType: SettingsCardType;
	text: string;
	to?: string; // internal link
}

export const AdminSettingsCards: ISettingsCard[] = [
	{
		settingType: SettingsCardType.Gamification,
		text: 'Gamification',
	},
	{
		settingType: SettingsCardType.Blitzes,
		text: 'Blitzes',
	},
	{
		settingType: SettingsCardType.DashAndReports,
		text: 'Dash & Reports',
	},
	{
		settingType: SettingsCardType.PrizesLog,
		text: 'Prizes Log',
	},
	{
		settingType: SettingsCardType.SlotMachines,
		text: 'Slot Machines',
	},
	{
		settingType: SettingsCardType.SalesAi,
		text: 'Manage Sales AI',
	},
	{
		settingType: SettingsCardType.Goals,
		text: 'Goals',
	},
	{
		settingType: SettingsCardType.Utilities,
		text: 'Utilities',
	},
	{
		settingType: SettingsCardType.PhoneNumbers,
		text: 'Phone Numbers',
	},
	{
		settingType: SettingsCardType.Categories,
		text: 'Categories',
	},
	{
		settingType: SettingsCardType.Voicemail,
		text: 'Voicemail',
		to: '/user/voicemail',
	},
	{
		href: `${process.env.APP_URL}/#/templates`,
		settingType: SettingsCardType.EmailTemplates,
		text: 'Email Templates',
	},
	{
		href: `${process.env.APP_URL}/#/settings/admin/manage-users`,
		settingType: SettingsCardType.ManageUsers,
		text: 'Manage Users',
	},
];

export const UserSettingsCards: ISettingsCard[] = [
	{
		href: `${process.env.APP_URL}/#/settings/password`,
		settingType: SettingsCardType.Password,
		text: 'Password',
	},
	{
		settingType: SettingsCardType.Voicemail,
		text: 'Voicemail',
		to: '/user/voicemail',
	},
	{
		settingType: SettingsCardType.SlotMachinePrizes,
		text: 'Slot Machine Prizes',
		to: '/slotmachine/prizes',
	},
	{
		href: `${process.env.APP_URL}/#/settings/email-signature`,
		settingType: SettingsCardType.EmailSignature,
		text: 'Signature',
	},
];
