import { CssSelectors } from '../../../../web/styles/styles';
import DaisyLegendBackgroundImageUrl from './daisyPrizeLegendBackground.svg';
import GoldRushLegendBackgroundImageUrl from './goldRushPrizeLegendBackground.svg';
import HighScoreLegendBackgroundImageUrl from './highScorePrizeLegendBackground.svg';
import { SlotMachineType } from '@ViewModels';
import { StyleSheet } from 'aphrodite';

const pageIndicatorHeight = 22;
const prizeRowSymbolsWidth = 90;
export const BackgroundImageUrls: Record<SlotMachineType, string> = {
	[SlotMachineType.BoozeCruise]: DaisyLegendBackgroundImageUrl,
	[SlotMachineType.Default]: HighScoreLegendBackgroundImageUrl,
	[SlotMachineType.GoldRush]: GoldRushLegendBackgroundImageUrl,
};

export const styleSheet = StyleSheet.create({
	container: {
		boxSizing: 'border-box',
		padding: '36px 40px 22px 40px',
		position: 'relative',
	},
	daisyContainer: {
		backgroundImage: `url(${BackgroundImageUrls[SlotMachineType.BoozeCruise]})`,
		backgroundRepeat: 'none',
		height: 324,
		padding: '80px 30px 22px 30px',
		width: 324,
	},
	goldRushContainer: {
		backgroundImage: `url(${BackgroundImageUrls[SlotMachineType.GoldRush]})`,
		backgroundRepeat: 'none',
		height: 247,
		width: 278,
	},
	highSoreContainer: {
		backgroundImage: `url(${BackgroundImageUrls[SlotMachineType.Default]})`,
		backgroundRepeat: 'none',
		height: 247,
		width: 278,
	},
	legendContent: {
		height: '100%',
		width: '100%',
	},
	legendContentDaisy: {
		margin: '0 auto',
		width: 200,
	},
	legendPage: {
		flexShrink: 0,
		height: '100%',
		width: '100%',
	},
	legendPager: {
		overflow: 'hidden',
	},
	legendPagerPageIndicator: {
		alignItems: 'center',
		display: 'flex',
		height: pageIndicatorHeight,
		justifyContent: 'center',
		marginTop: 10,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	legendPagerPageIndicatorButton: {
		':hover': {
			opacity: 0.33,
			transform: 'scale(1.1, 1.1)',
			transition: '150ms east-out',
		},
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		opacity: 0.25,
	},
	legendPagerPages: {
		display: 'flex',
		height: `calc(100% - ${pageIndicatorHeight + 20}px)`,
		willChange: 'transform',
	},
	legendPagerSpinsLeft: {
		color: '#fff',
		fontSize: 11,
		left: '50%',
		letterSpacing: '1.1px',
		position: 'absolute',
		textTransform: 'uppercase',
		top: 'calc(100% + 10px)',
		transform: 'translate(-50%, 0)',
	},
	prizeList: {
		listStyle: 'none',
		width: '100%',
		...CssSelectors.allChildren(
			{
				marginTop: 14,
				width: '100%',
			},
			':not(:first-child)'
		),
	},
	prizeRow: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		width: '100%',
	},
	prizeRowSymbols: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-evenly',
		width: prizeRowSymbolsWidth,
	},
	prizeRowText: {
		marginLeft: 8,
		width: `calc(100% - ${prizeRowSymbolsWidth + 8}px)`,
	},
});
