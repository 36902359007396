import { css } from 'aphrodite';
import { TestTranscriptionComponent } from '../../../components/ai/LiveScorecard/TestTranscriptionComponent';
import { BasicPage } from '../../BasicPage';
import { styleSheet } from './styles';

export const TranscriptionTest = () => {
	return (
		<BasicPage className={css(styleSheet.container)} title='Transcription Test Tool'>
			<TestTranscriptionComponent />
		</BasicPage>
	);
};
