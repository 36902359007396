import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useStreamingResponseMutation } from '../../../../hooks/useStreamingResponseMutation';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../../models/Logging';
import { useAIAssistantCreateConversationMutation } from '../../../../queries/AI/useAIAssistantCreateConversationMutation';
import { Button } from '../../../../web/components/Button';
import { Toggle } from '../../../../web/components/Toggle';
import { AIDA_ASSISTANT_NAME } from './index';
import { styleSheet } from './styles';

export const TestTranscriptionComponent: React.FC = () => {
	const errorMessages = useErrorMessages();
	const logger = useEventLogging('TestTranscriptionComponent');
	const userSession = useUserSession();
	const [transcription, setTranscription] = React.useState<string>('');
	const [systemMessage, setSystemMessage] = React.useState<string>('');
	const [isPhoneCallOngoing, setIsPhoneCallOngoing] = React.useState<boolean>(true);
	const [aiResponse, setAiResponse] = React.useState<string>('');

	// Default system messages
	const defaultOngoingSystemMessage =
		'You are a helpful assistant that analyzes realtime calls between a lead and an sdr (sales development representative) and gives the sdr advice in real time. See how quickly you can stream responses, dont wait and analyze too much.';
	const defaultSummarySystemMessage =
		'You are a helpful assistant that analyzes calls between a lead and an sdr (sales development representative) and gives the sdr feedback on their performance.';

	React.useEffect(() => {
		// Set default system message based on phone call status
		setSystemMessage(isPhoneCallOngoing ? defaultOngoingSystemMessage : defaultSummarySystemMessage);
	}, [isPhoneCallOngoing]);

	const aiAssistantCreateConversationMutation = useAIAssistantCreateConversationMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			logger.logApiError('aiAssistantCreateConversationMutation', error);
		},
		onSuccess: conversation => {
			// Use the user-provided system message or the default one
			const systemMessageObj = {
				id: null as any,
				role: Api.AIChatRole.System,
				text: systemMessage,
			};

			// Send the prompt to the AI
			aiPromptMutation.mutate({
				assistantName: AIDA_ASSISTANT_NAME,
				conversationId: conversation.id,
				chatRequest: {
					chatMessages: [
						systemMessageObj,
						{
							role: Api.AIChatRole.User,
							text: transcription,
							id: null,
						},
					],
				},
			});
		},
	});

	const aiPromptMutation = useStreamingResponseMutation({
		mutationFn: (params: {
			assistantName: string;
			conversationId: string;
			chatRequest: Api.AIChatRequest;
		}) =>
			new Promise<Response>((resolve, reject) =>
				userSession.webServiceHelper.callWebServiceRawResponse(
					Api.ImpersonationBroker.composeApiUrl({
						urlPath: `ai/assistant/${params.assistantName}/conversation/${params.conversationId}/stream`,
					}),
					'POST',
					params.chatRequest,
					resolve,
					reject
				)
			),
		onEvent: (event, _params) => {
			const data = JSON.parse(event.data);
			if (event.event !== 'Response') {
				return;
			}
			const messageText = data.Value.Message.Text;
			setAiResponse(prev => prev + messageText);
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logger.logApiError('aiPrompt', error as Api.IOperationResultNoValue);
		},
	});

	const isLoading = aiAssistantCreateConversationMutation.isLoading || aiPromptMutation.isLoading;
	const isStreaming = aiPromptMutation.isStreaming;

	const handleSubmit = () => {
		if (!transcription.trim()) {
			return;
		}

		setAiResponse('');

		// Create a conversation
		aiAssistantCreateConversationMutation.mutate({
			assistantName: AIDA_ASSISTANT_NAME,
		});
	};

	const handleToggleChange = (checked: boolean) => {
		setIsPhoneCallOngoing(checked);
	};

	return (
		<div className={css(styleSheet.testContainer)}>
			<h2 className={css(styleSheet.testHeader)}>Transcription Test Tool</h2>

			<div className={css(styleSheet.testToggleContainer)}>
				<span>{isPhoneCallOngoing ? 'Ongoing Call Mode' : 'Summary Mode'}</span>
				<Toggle
					id='test-mode-toggle'
					isOn={isPhoneCallOngoing}
					onToggleCheckChanged={handleToggleChange}
					text={isPhoneCallOngoing ? 'Ongoing Call' : 'Summary'}
				/>
			</div>

			<div className={css(styleSheet.testInputContainer)}>
				<label htmlFor='systemMessage' className={css(styleSheet.testLabel)}>
					System Message:
				</label>
				<textarea
					id='systemMessage'
					value={systemMessage}
					onChange={e => setSystemMessage(e.target.value)}
					className={css(styleSheet.testTextarea)}
					placeholder='Enter system message...'
					rows={4}
				/>
			</div>

			<div className={css(styleSheet.testInputContainer)}>
				<label htmlFor='transcription' className={css(styleSheet.testLabel)}>
					Transcription:
				</label>
				<textarea
					id='transcription'
					value={transcription}
					onChange={e => setTranscription(e.target.value)}
					className={css(styleSheet.testTextarea)}
					placeholder='Enter call transcription...'
					rows={10}
				/>
			</div>

			<Button
				onClick={handleSubmit}
				disabled={isLoading || isStreaming || !transcription.trim()}
				className={css(styleSheet.testSubmitButton)}
			>
				{isPhoneCallOngoing ? 'Get Real-time Advice' : 'Get Call Feedback'}
			</Button>

			{(isLoading || isStreaming) && (
				<div className={css(styleSheet.testLoading)}>
					{isLoading ? 'Initializing...' : isStreaming ? 'Receiving response...' : ''}
				</div>
			)}

			{aiResponse && (
				<div className={css(styleSheet.testResponseContainer)}>
					<h3 className={css(styleSheet.testResponseHeader)}>AI Response:</h3>
					<div className={css(styleSheet.testResponse)}>{aiResponse}</div>
				</div>
			)}
		</div>
	);
};
