/* eslint-disable no-prototype-builtins */

/* eslint-disable @typescript-eslint/no-shadow */
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { IRange, IUser } from '../../../extViewmodels';
import { ISelectOption } from '../../../web/components/DeprecatedSelect';
import { TransparentButton } from '../../components/TransparentButton';
import { Fill } from '../../components/generics/Fill';
import { CategorySelect } from '../../components/leadView/components/CategorySelect';
import { CompanyTagsSelect } from '../../components/leadView/components/CompanyTagsSelect';
import { DatePicker } from '../../components/leadView/components/DatePicker';
import { Group } from '../../components/leadView/components/Group';
import { ManagerSelect } from '../../components/leadView/components/ManagerSelect';
import { OutcomeSelect } from '../../components/leadView/components/OutcomeSelect';
import { RecordingChannelsSelect } from '../../components/leadView/components/RecordingChannelsSelect';
import { RoleSelect } from '../../components/leadView/components/RoleSelect';
import { TeamSelect } from '../../components/leadView/components/TeamSelect';
import { TranscriptionStatusSelect } from '../../components/leadView/components/TranscriptionStatusSelect';
import { UserSelect } from '../../components/leadView/components/UserSelect';
import { ILeadViewFilter } from '../../entities/LeadView/useLeadView';
import { ViewportContext } from '../../hooks/useViewport';
import { FieldKey } from '../../viewModels/form';
import { styleSheet } from './styles';

interface ILeadViewFilterProps {
	filter?: ILeadViewFilter;
	onChange: (filter: ILeadViewFilter) => void;
}

export enum CallLogFilterKeys {
	Category = 'category',
	CompanyTag = 'companyTag',
	Date = 'date',
	Manager = 'manager',
	Outcomes = 'outcomes',
	RecordingChannels = 'recordingChannels',
	Team = 'team',
	TranscriptionStatus = 'transcriptionStatus',
	User = 'user',
	UserRole = 'userRole',
}

export const Filters: React.FC<ILeadViewFilterProps> = ({ children, filter, onChange }) => {
	const { isBelowSmall } = useContext(ViewportContext);

	const [toggleFilter, setToggleFilter] = useState(false);
	const [animationStart, setAnimationStart] = useState(false);
	const [filterIsOpened, setFilterIsOpened] = useState(false);

	const openFilterAnimation = {
		from: { height: 0 },
		to: { height: 356 },
	};

	const closeFilterAnimation = {
		from: { height: 356 },
		to: { height: 0 },
	};

	const handleAnimationStart = useCallback(() => {
		setAnimationStart(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [animationStart]);

	const handleAnimationRest = useCallback(
		a => {
			setFilterIsOpened(a.height === 356);
			setAnimationStart(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[animationStart]
	);

	const [spring, springApi] = useSpring(() => ({
		config: { ...config.default },
		onRest: handleAnimationRest,
		onStart: handleAnimationStart,
	}));

	function handleChange<T>(key: CallLogFilterKeys) {
		return (item: T) => {
			const isOption = (item as ISelectOption<string>)?.hasOwnProperty('dataContext');
			const isEmptyValue = !(isOption ? (item as ISelectOption<string>)?.dataContext : item);
			if (isEmptyValue) {
				delete filter[key];

				onChange(filter);
				return;
			}
			onChange({ ...filter, [key]: item });
		};
	}

	const handleToggleFilters = () => {
		if (toggleFilter) {
			springApi(closeFilterAnimation);
		} else {
			springApi(openFilterAnimation);
		}
		setToggleFilter(!toggleFilter);
	};

	const handleClearFilters = () => {
		onChange(null);
	};

	const overflow = filterIsOpened ? (animationStart ? 'hidden' : 'initial') : 'hidden';

	if (isBelowSmall) {
		return (
			<Group className={css(styleSheet.filterMobile)} vertical={true}>
				<Group>
					<UserSelect user={filter?.user} onChange={handleChange<IUser>(CallLogFilterKeys.User)} />
					<TransparentButton className={css(styleSheet.filterButton)} onClick={handleToggleFilters}>
						Show {toggleFilter ? 'less' : 'more'} filters
					</TransparentButton>
				</Group>
				<animated.div
					style={{
						height: 0,
						overflow,
						...spring,
					}}
				>
					<Group>
						<Group vertical={true} fullWidth={false}>
							<ManagerSelect onChange={handleChange<IUser>(CallLogFilterKeys.Manager)} />
							<RoleSelect onChange={handleChange<string>(CallLogFilterKeys.UserRole)} />
							<TeamSelect onChange={handleChange<string>(CallLogFilterKeys.Team)} />

							{/* @ts-ignore */}
							<DatePicker initialRange={filter?.date} onChange={handleChange<IRange<Date>>(CallLogFilterKeys.Date)} />
							<OutcomeSelect
								closeOnClickInside={true}
								isMulti={true}
								onChange={handleChange<ISelectOption<FieldKey>[]>(CallLogFilterKeys.Outcomes)}
							/>
							<CompanyTagsSelect onChange={handleChange<string>(CallLogFilterKeys.CompanyTag)} />
							<CategorySelect
								defaultValue={{ name: filter?.category }}
								onChange={handleChange<string>(CallLogFilterKeys.Category)}
							/>
						</Group>
						<TransparentButton className={css(styleSheet.filterButton)} onClick={handleClearFilters}>
							Clear filters
						</TransparentButton>
					</Group>
				</animated.div>
			</Group>
		);
	}

	return (
		<>
			<Group className={css(styleSheet.filter)} vertical={true} hCentered={true}>
				<Group fullWidth={false}>
					<UserSelect user={filter?.user} onChange={handleChange<IUser>(CallLogFilterKeys.User)} />
					<ManagerSelect onChange={handleChange<IUser>(CallLogFilterKeys.Manager)} />
					<RoleSelect onChange={handleChange<string>(CallLogFilterKeys.UserRole)} />
					<TeamSelect onChange={handleChange<string>(CallLogFilterKeys.Team)} />
				</Group>
				<Group className={css(styleSheet.filterGroup)} fullWidth={false}>
					{/* @ts-ignore */}
					<DatePicker initialRange={filter?.date} onChange={handleChange<IRange<Date>>(CallLogFilterKeys.Date)} />
					<OutcomeSelect
						isMulti={true}
						onChange={handleChange<ISelectOption<FieldKey>[]>(CallLogFilterKeys.Outcomes)}
					/>
					<CompanyTagsSelect onChange={handleChange<string>(CallLogFilterKeys.CompanyTag)} />
					<CategorySelect
						defaultValue={{ name: filter?.category }}
						onChange={handleChange<string>(CallLogFilterKeys.Category)}
					/>
					<TranscriptionStatusSelect
						onChange={handleChange<ISelectOption<string>>(CallLogFilterKeys.TranscriptionStatus)}
						selected={filter?.transcriptionStatus}
					/>
					<RecordingChannelsSelect
						onChange={handleChange<ISelectOption<string>>(CallLogFilterKeys.RecordingChannels)}
						selected={filter?.recordingChannels}
					/>
				</Group>
				<Group>
					<Fill />
				</Group>
			</Group>
			{children}
		</>
	);
};
