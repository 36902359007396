import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { QuickSightDashboardViewModel } from '../../../viewmodels/AdminViewModels';
import { DeprecatedSelect, ISelectOption } from '../../../web/components/DeprecatedSelect';
import { Report } from '../../components/Report';
import { BasicPage } from '../BasicPage';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

type IDashboard = ISelectOption<string>;

const realMagicSettingsCards: IDashboard[] = [
	{
		dataContext: QuickSightDashboardViewModel.AIDARealMagicDialsId,
		id: QuickSightDashboardViewModel.AIDARealMagicDialsId,
		text: 'Dials',
	},
	{
		dataContext: QuickSightDashboardViewModel.AIDARealMagicProspectingKPIsId,
		id: QuickSightDashboardViewModel.AIDARealMagicProspectingKPIsId,
		text: 'Prospecting KPIs',
	},
	{
		dataContext: QuickSightDashboardViewModel.AIDARealMagicCloserKPIsId,
		id: QuickSightDashboardViewModel.AIDARealMagicCloserKPIsId,
		text: 'Closer KPIs',
	},
	{
		dataContext: QuickSightDashboardViewModel.AIDARealMagicBookedByOverviewId,
		id: QuickSightDashboardViewModel.AIDARealMagicBookedByOverviewId,
		text: 'Booked by Overview',
	},
	{
		dataContext: QuickSightDashboardViewModel.AIDARealMagicDemoQualityId,
		id: QuickSightDashboardViewModel.AIDARealMagicDemoQualityId,
		text: 'Demo Quality',
	},
	{
		dataContext: QuickSightDashboardViewModel.AIDARealMagicCircleOfMagicId,
		id: QuickSightDashboardViewModel.AIDARealMagicCircleOfMagicId,
		text: 'Circle of Magic',
	},
];

const customerSettingsCards: IDashboard[] = [
	{
		dataContext: QuickSightDashboardViewModel.AIDACustomersDialsId,
		id: QuickSightDashboardViewModel.AIDACustomersDialsId,
		text: 'Dials',
	},
];

export const ReportingContainer: React.FC<IProps> = ({ className = '' }) => {
	const userSession = useUserSession();

	const [dashboardId, setDashboardId] = useState<string>(undefined);

	const [dashboardIds, setDashboardIds] = useState<IDashboard[]>(undefined);

	React.useEffect(() => {
		const valid = userSession.account.isLevitateSalesCoffeeAccount ? realMagicSettingsCards : customerSettingsCards;
		setDashboardIds(valid);
		setDashboardId(valid[0].dataContext);
	}, [userSession.account.isLevitateSalesCoffeeAccount]);

	const onDashboardSelected = (option: IDashboard) => {
		setDashboardId(option.dataContext);
	};

	return (
		<BasicPage className={`${css(styleSheet.settingsContainer)} ${className}`} title='Reporting'>
			<div style={{ transform: 'translate(0px, -60px)' }}>
				{dashboardIds?.length > 0 && (
					<div className={css(styleSheet.selectRow)}>
						<DeprecatedSelect
							dropdownStyles={[styleSheet.selectDropdown]}
							onOptionClick={onDashboardSelected}
							options={dashboardIds}
							selectedOption={dashboardIds.find(x => x.id === dashboardId)}
							styles={[styleSheet.select]}
						/>
					</div>
				)}
				{dashboardId && (
					<div style={{ height: 'calc(100vh - 220px)', width: '100%' }}>
						<Report dashboardId={dashboardId} />
					</div>
				)}
			</div>
		</BasicPage>
	);
};
