import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		padding: 16,
	},
	contentColumn: {},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 16,
	},
	spacer: {
		flex: 1,
	},
});
