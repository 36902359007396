import { css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../web/components/Button';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { TwilioContextProvider } from '../../../components/ai/context';
import { BasicPage } from '../../BasicPage';
import { ScorecardTemplatesTable } from './ScorecardTemplatesTable';
import { styleSheet } from './styles';

export const SalesAiSettings = () => {
	const isLoading = false;
	const history = useHistory();

	const navigateToTranscriptionTest = () => {
		history.push('/settings/transcriptionTest');
	};

	if (isLoading) {
		return <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} />;
	}

	return (
		<BasicPage className={css(styleSheet.container)} title='Sales AI Settings'>
			<div className={css(styleSheet.headerContainer)}>
				<div className={css(styleSheet.spacer)} />
				<Button onClick={navigateToTranscriptionTest} kind='primary'>
					Open Transcription Test Tool
				</Button>
			</div>
			<TwilioContextProvider>
				<ScorecardTemplatesTable />
			</TwilioContextProvider>
		</BasicPage>
	);
};
