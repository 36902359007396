import { StyleDeclaration, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormFieldType, IFormField } from '../../../../extViewmodels';
import { Noop, timezoneMap } from '../../../../extViewmodels/Utils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useModal } from '../../../../models/hooks/useModal';
import { DeprecatedSelect, ISelectOption } from '../../../../web/components/DeprecatedSelect';
import { useQueue } from '../../../hooks/queue';
import { IFormFieldOption } from '../../../viewModels/form';
import { FollowUpOptionIds } from '../../../viewModels/phonecall';
import { ILeadFollowUp } from '../../../viewModels/queue';
import { FollowUpModal, IFollowUpCallbackArgs } from '../FollowUpModal';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	followUpSelectStyles?: StyleDeclaration[];
	label: string;
}

const noneAndCustomOptions = [
	{
		dataContext: {
			fieldType: FormFieldType.Custom,
			label: 'custom',
			name: 'custom',
		},
		id: 'custom',
		text: 'At a specific time...',
	},
	{
		dataContext: {
			fieldType: null,
			label: 'none',
			name: 'none',
		},
		id: 'none',
		text: 'None',
	},
];

const FollowUpSelectBase: React.FC<IProps> = ({ className = '', followUpSelectStyles = [], label }) => {
	const queue = useQueue();
	const userSession = useUserSession();

	const [selectedOption, setSelectedOption] = useState<ISelectOption<IFormField<IFormFieldOption>>>(null);
	const followUpModal = useModal(false, Noop, [], true, false);
	const [options, setOptions] = useState<ISelectOption<IFormField<IFormFieldOption>>[]>([]);
	// if there is a follow up more than 10 minutes in the future, prefer that as the default
	const tenMinutesInTheFuture = moment().add(10, 'minutes');

	useEffect(() => {
		if (!selectedOption) {
			return;
		}
		if (selectedOption.id === 'none') {
			queue.lead.setSelectedFollowUp(null);
		} else if (selectedOption.id === 'custom') {
			if (
				queue.lead?.followUp?.scheduled &&
				moment(queue.lead?.followUp?.followUpDate).isAfter(tenMinutesInTheFuture)
			) {
				queue.lead.setSelectedFollowUp(queue.lead.followUp);
			}
		} else {
			queue.lead.setSelectedFollowUp(selectedOption.id as FollowUpOptionIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOption]);

	useEffect(() => {
		if (!queue.lead?.followUpOptions) {
			setSelectedOption(null);
			return;
		}

		const opts = (queue.lead?.followUpOptions?.options || [])
			.map(option => {
				return {
					dataContext: option,
					id: option.id,
					text: option.label,
				};
			})

			.concat(noneAndCustomOptions);

		setOptions(opts);
		if (queue.lead?.followUp?.scheduled && moment(queue.lead?.followUp?.followUpDate).isAfter(tenMinutesInTheFuture)) {
			setSelectedOption(opts.find(option => option.id === 'custom'));
		} else {
			setSelectedOption(opts.find(option => option.id === queue.lead?.followUpOptions?.defaultValueId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.lead?.followUpOptions]);

	useEffect(() => {
		if (queue.lead?.followUp?.scheduled && moment(queue.lead?.followUp?.followUpDate).isAfter(tenMinutesInTheFuture)) {
			setSelectedOption(options.find(option => option.id === 'custom'));
			queue.lead.setSelectedFollowUp(queue.lead.followUp);
		} else {
			setSelectedOption(options.find(option => option.id === queue.lead?.followUpOptions?.defaultValueId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.lead?.followUp]);

	const onOptionClick = (option: ISelectOption<IFormField<IFormFieldOption>>) => {
		if (option.dataContext.fieldType === FormFieldType.Custom) {
			followUpModal.setIsOpen(true)();
		}
		setSelectedOption(option);
	};

	const renderTitle = () => {
		let content: JSX.Element = null;
		const followUpDate = (queue.lead?.selectedFollowUp as ILeadFollowUp)?.followUpDate;

		if (!selectedOption) {
			content = <span>Select time</span>;
		} else if (selectedOption.dataContext.fieldType === FormFieldType.Custom) {
			content = !followUpDate ? (
				<span className={css(styleSheet.selectedOptionText)}>Select a date</span>
			) : (
				<span className={css(styleSheet.selectedOptionText)}>
					{`${moment(followUpDate).format('M/D/YY h:mm a')} ${
						timezoneMap[userSession.user.userPreferences?.timeZone || momentTz.tz.guess()]
					}`}
				</span>
			);
		} else {
			content = <span className={css(styleSheet.selectedOptionText)}>{selectedOption.text}</span>;
		}

		return <div className={`follow-up-title ${css(styleSheet.title)}`}>{content}</div>;
	};

	if (!queue.lead?.followUpOptions || queue.lead.followUpOptions?.isDisabled) {
		return <div />;
	}

	const onClickToConfirm = ({ importance, name, selectedDate }: IFollowUpCallbackArgs) => {
		queue.lead.setSelectedFollowUp({
			followUpDate: new Date(selectedDate.toDate()).toISOString(),
			importance,
			name,
			scheduled: true,
		});
		followUpModal.setIsOpen(false)();
	};

	return (
		<div className={`${css(styleSheet.followUpSelectContainer)} ${className}`}>
			{!!label && <div className={`follow-up-label ${css(styleSheet.label)}`}>{label}</div>}
			<DeprecatedSelect
				dropdownStyles={[styleSheet.dropdown]}
				options={options}
				onOptionClick={onOptionClick}
				selectedOption={selectedOption}
				selectedOptionTitle={renderTitle()}
				styles={[styleSheet.selectContainer, ...followUpSelectStyles]}
				triggerStyles={[styleSheet.trigger]}
			/>
			<FollowUpModal {...followUpModal} onClickToConfirm={onClickToConfirm} />
		</div>
	);
};

export const FollowUpSelect = observer(FollowUpSelectBase);
