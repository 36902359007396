import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useStreamingResponseMutation } from '../../../../hooks/useStreamingResponseMutation';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../../models/Logging';
import { useAIAssistantCreateConversationMutation } from '../../../../queries/AI/useAIAssistantCreateConversationMutation';
import { getConversationChatMessageQueryKey } from '../../../../queries/AIConversation/useAIConversationChatMessageQuery';
import { Button } from '../../../../web/components/Button';
import { DeprecatedPopover, PopoverType } from '../../../../web/components/DeprecatedPopover';
import { SparklesIcon } from '../../../../web/components/svgs/icons/SparklesIcon';
import { SuccessCheckIcon } from '../../../../web/components/svgs/icons/SuccessCheckIcon';
import { reactQueryClient } from '../../../../web/ReactQueryProvider';
import { nameCircles } from '../../../../web/styles/colors';
import { useTwilio } from '../../../components/ai/context';
import { useWebsockets } from '../../../hooks';
import { styleSheet } from './styles';

export const AIDA_ASSISTANT_NAME = 'SalesLiveCoachingAssistant';

export const GoalsList = ({
	topicProgressions,
	scorecard,
	complete = false,
}: {
	topicProgressions: Api.IScorecardTopicProgress[];
	scorecard: Api.IScorecard;
	complete?: boolean;
}) => {
	const [hoveredTopics, setHoveredTopics] = React.useState<Api.IScorecardTopicProgress[]>([]);

	const getLabel = (topicProgression: Api.IScorecardTopicProgress) => {
		const { topics } = scorecard.scorecardTemplateVersion;
		const matchingTopic = topics.find(x => x.identifier === topicProgression.topicIdentifier);
		return matchingTopic?.label ?? topicProgression.topicIdentifier;
	};

	const getSummary = (topicProgression: Api.IScorecardTopicProgress) => {
		const { topics } = scorecard.scorecardTemplateVersion;
		const matchingTopic = topics.find(x => x.identifier === topicProgression.topicIdentifier);
		return matchingTopic?.summary ?? '';
	};

	const onHover = (topicProgression: Api.IScorecardTopicProgress) => {
		if (!hoveredTopics.includes(topicProgression)) {
			setHoveredTopics([...hoveredTopics, topicProgression]);
		}
	};

	const onLeave = (topicProgression: Api.IScorecardTopicProgress) => {
		setHoveredTopics(hoveredTopics.filter(x => x !== topicProgression));
	};

	return (
		<>
			{topicProgressions.map(topicProgression => {
				const isHovering = hoveredTopics.includes(topicProgression);
				return (
					<DeprecatedPopover
						key={`topic-progression-${topicProgression.topicIdentifier}`}
						anchor={
							<div
								className={css(styleSheet.progression)}
								key={topicProgression.topicIdentifier}
								onMouseOver={() => onHover(topicProgression)}
								onMouseLeave={() => onLeave(topicProgression)}
							>
								<SuccessCheckIcon fillColor={complete ? null : nameCircles} ringColor={complete ? null : '#F9F9F9'} />
								<span className={isHovering ? css(styleSheet.isHovering) : null}>{getLabel(topicProgression)}</span>
							</div>
						}
						isOpen={isHovering}
						type={PopoverType.white}
						preferredPlacement='above'
					>
						<div className={css(styleSheet.topicPopover)}>{getSummary(topicProgression)}</div>
					</DeprecatedPopover>
				);
			})}
		</>
	);
};

export const LiveScorecard = ({
	scorecard,
	toggle,
	isPhoneCallOngoing,
}: {
	scorecard: Api.IScorecard;
	toggle?: React.ReactNode;
	isPhoneCallOngoing: boolean;
}) => {
	const {
		completeTopicIdentifiers,
		setCompleteTopicIdentifiers,
		incompleteTopicIdentifiers,
		setIncompleteTopicIdentifiers,
		realtimeTranscriptionUtterances,
	} = useTwilio();
	const errorMessages = useErrorMessages();
	const logger = useEventLogging('LiveScorecard');
	const userSession = useUserSession();
	const [isAiAssistantOpen, setIsAiAssistantOpen] = React.useState(false);
	const [aiResponse, setAiResponse] = React.useState('');

	const onHover = () => {
		setLiveScoreHover(true);
	};

	const onLeave = () => {
		setLiveScoreHover(false);
	};
	const [liveScoreHover, setLiveScoreHover] = React.useState<boolean>(null);
	const webSockets = useWebsockets();
	const webSocketsConnected =
		(webSockets?.connectionStatus ?? Api.RemoteEventsConnectionStatus.Disconnected) ===
		Api.RemoteEventsConnectionStatus.Connected;

	const hasNecessarySubscriptions =
		webSockets.hasSubscription('callSuggestionHintEvent') && webSockets.hasSubscription('scorecardEvent');
	const webSocketsFullyConnected = webSocketsConnected && hasNecessarySubscriptions;

	const completedPercent = Math.floor(
		(completeTopicIdentifiers.length / (completeTopicIdentifiers.length + incompleteTopicIdentifiers.length)) * 100
	);

	const completeProgressions = scorecard.topicProgressions.filter(
		x => completeTopicIdentifiers.indexOf(x.topicIdentifier) > -1
	);
	const incompleteProgressions = scorecard.topicProgressions.filter(
		x => incompleteTopicIdentifiers.indexOf(x.topicIdentifier) > -1
	);

	const aiAssistantCreateConversationMutation = useAIAssistantCreateConversationMutation({
		onError: error => {
			errorMessages.pushApiError(error);
			logger.logApiError('aiAssistantCreateConversationMutation', error);
		},
		onSuccess: conversation => {
			// Format the transcription utterances
			const formattedUtterances = realtimeTranscriptionUtterances.join('\n\n');

			const systemMessage = isPhoneCallOngoing
				? {
						id: null as any,
						role: Api.AIChatRole.System,
						text: 'You are a helpful assistant that analyzes realtime calls between a lead and an sdr (sales development representative) and gives the sdr advice in real time. See how quickly you can stream responses, dont wait and analyze too much.',
					}
				: {
						id: null as any,
						role: Api.AIChatRole.System,
						text: 'You are a helpful assistant that analyzes calls between a lead and an sdr (sales development representative) and gives the sdr feedback on their performance.',
					};

			// Set the conversation data in React Query
			reactQueryClient.setQueryData(
				getConversationChatMessageQueryKey({
					id: conversation.id,
				}),
				[
					systemMessage,
					{
						role: Api.AIChatRole.User,
						text: formattedUtterances,
						id: null,
					},
				]
			);
			// Send the prompt to the AI
			aiPromptMutation.mutate({
				assistantName: AIDA_ASSISTANT_NAME,
				conversationId: conversation.id,
				chatRequest: {
					chatMessages: [
						systemMessage,
						{
							role: Api.AIChatRole.User,
							text: formattedUtterances,
							id: null,
						},
					],
				},
			});
		},
	});

	const aiPromptMutation = useStreamingResponseMutation({
		mutationFn: (params: {
			assistantName: string;
			conversationId: string;
			chatRequest: Api.AIChatRequest;
		}) =>
			new Promise<Response>((resolve, reject) =>
				userSession.webServiceHelper.callWebServiceRawResponse(
					Api.ImpersonationBroker.composeApiUrl({
						urlPath: `ai/assistant/${params.assistantName}/conversation/${params.conversationId}/stream`,
					}),
					'POST',
					params.chatRequest,
					resolve,
					reject
				)
			),
		onEvent: (event, params) => {
			const data = JSON.parse(event.data);
			if (event.event !== 'Response') {
				return;
			}
			const messageText = data.Value.Message.Text;
			setAiResponse(prev => prev + messageText);

			reactQueryClient.setQueryData(
				getConversationChatMessageQueryKey({
					id: params.conversationId,
				}),
				(messages: Api.AIChatMessage[]) => {
					if (messages?.length > 0 && messages[messages.length - 1].role === Api.AIChatRole.Assistant) {
						const lastAssistantMessage = messages.pop();
						return [...messages, { ...lastAssistantMessage, text: lastAssistantMessage.text + messageText }];
					}
					return [...(messages || []), { id: data.Value.Id, text: messageText, role: Api.AIChatRole.Assistant }];
				}
			);
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logger.logApiError('aiPrompt', error as Api.IOperationResultNoValue);
		},
	});

	const handleAskAiAssistant = () => {
		setIsAiAssistantOpen(true);
		setAiResponse('');

		// Create a conversation
		aiAssistantCreateConversationMutation.mutate({
			assistantName: AIDA_ASSISTANT_NAME,
		});
	};

	const handleCloseAiAssistant = () => {
		setIsAiAssistantOpen(false);
		setAiResponse('');
	};

	React.useEffect(() => {
		if (!scorecard?.topicProgressions?.length) {
			return;
		}

		setCompleteTopicIdentifiers(scorecard.topicProgressions.filter(x => x.isCompleted).map(x => x.topicIdentifier));
		setIncompleteTopicIdentifiers(scorecard.topicProgressions.filter(x => !x.isCompleted).map(x => x.topicIdentifier));
	}, [scorecard, setCompleteTopicIdentifiers, setIncompleteTopicIdentifiers]);

	return (
		<div className={css(styleSheet.container)}>
			<header className={css(styleSheet.header)}>
				<div className={css(styleSheet.oneQuarter)}>
					<div className={css(styleSheet.currentScore)}>
						<span>Score: </span>
						<span className={css(completedPercent > 79 ? styleSheet.green : styleSheet.red)}>{completedPercent}%</span>
					</div>
				</div>

				<DeprecatedPopover
					key='live-score-card-popover'
					anchor={
						<div className={css(styleSheet.oneHalf)} onMouseEnter={() => onHover()}>
							<div className={css(webSocketsFullyConnected ? styleSheet.greenCircle : styleSheet.redCircle)}>⬤</div>
							<h5>Live Score Card</h5>
						</div>
					}
					isOpen={liveScoreHover}
					dismissOnClickOutside={true}
					onRequestClose={onLeave}
					type={PopoverType.white}
					preferredPlacement='above'
				>
					<div className={css(styleSheet.topicPopover)}>
						Websockets: {webSocketsConnected ? 'Connected' : 'Disconnected'}
						<br />
						Subscriptions: {hasNecessarySubscriptions ? 'Subscribed' : 'Not Subscribed'}
						<br />
						Last Message Received: {moment(webSockets.lastMessageReceivedTime).format('h:mm:ss a')}
						<br />
						Last Suggestion Message Received:{' '}
						{webSockets.lastMessageReceivedTimeBySubscriptionName('callSuggestionHintEvent')
							? moment(webSockets.lastMessageReceivedTimeBySubscriptionName('callSuggestionHintEvent')).format(
									'h:mm:ss a'
								)
							: 'Never'}
						<br />
						Last Scorecard Message Received:{' '}
						{webSockets.lastMessageReceivedTimeBySubscriptionName('scorecardEvent')
							? moment(webSockets.lastMessageReceivedTimeBySubscriptionName('scorecardEvent')).format('h:mm:ss a')
							: 'Never'}
					</div>
				</DeprecatedPopover>

				<div className={css(styleSheet.oneQuarter)}>{toggle}</div>
			</header>

			<div className={css(styleSheet.progressions)}>
				<GoalsList topicProgressions={completeProgressions} complete scorecard={scorecard} />
				<GoalsList topicProgressions={incompleteProgressions} scorecard={scorecard} />
			</div>

			<div className={css(styleSheet.realtimeTranscriptionUtterances)}>
				{realtimeTranscriptionUtterances.map((utterance: string, index: number) => {
					return <div key={`${utterance}-${index}`}>{utterance}</div>;
				})}
			</div>

			<div className={css(styleSheet.aiContainer)}>
				{!isAiAssistantOpen ? (
					<Button kind='primary' onClick={handleAskAiAssistant}>
						{isPhoneCallOngoing ? 'Ask AI Assistant' : 'Analyze Call'}
					</Button>
				) : (
					<div className={css(styleSheet.aiAssistantContainer)}>
						<div className={css(styleSheet.aiAssistantHeader)}>
							<div className={css(styleSheet.aiAssistantHeaderLeft)}>
								<SparklesIcon />
								<span className={css(styleSheet.aiAssistantTitle)}>AI Assistant</span>
							</div>
							<Button kind='secondary' onClick={handleCloseAiAssistant}>
								Close
							</Button>
						</div>
						<div className={css(styleSheet.aiAssistantContent)}>
							<div className={css(styleSheet.aiAssistantResponse)}>{aiResponse}</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
