import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modifiers } from 'react-day-picker/types/common';
import { timezoneMap } from '../../../extViewmodels/Utils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Button } from '../../../web/components/Button';
import { DayPicker } from '../../../web/components/DayPicker';
import { DeprecatedSelect, ISelectOption } from '../../../web/components/DeprecatedSelect';
import { ITimezoneData, TimezonePicker } from '../../../web/components/TimezonePicker';
import { useQueue } from '../../hooks/queue';
import { getTimezoneAdjustedTime, getTimezoneUnadjustedTime, timezones } from '../../viewModels/queue';
import { TimePicker } from '../TimePicker';
import { styleSheet } from './styles';

interface IEditSendOptionsDayPickerModifiers {
	disabled?: boolean;
	hasCampaign?: boolean;
	hasCompletedCampaign?: boolean;
	hasQueuedCampaign?: boolean;
	selected?: boolean;
}

interface IProps {
	canAdjustTime?: boolean;
	className?: string;
	day: moment.Moment;
	hideTime?: boolean;
	onCancel: () => void;
	onSave: (day: moment.Moment) => void;
	onChange: (day: moment.Moment) => void;
}

const timezoneOptions: ISelectOption<timezones>[] = [
	{
		dataContext: 'US/Eastern',
		id: 'timezone-us-eastern',
		text: 'US/Eastern',
	},
	{
		dataContext: 'US/Central',
		id: 'timezone-us-central',
		text: 'US/Central',
	},
	{
		dataContext: 'US/Mountain',
		id: 'timezone-us-mountain',
		text: 'US/Mountain',
	},
	{
		dataContext: 'US/Pacific',
		id: 'timezone-us-pacific',
		text: 'US/Pacific',
	},
	{
		dataContext: 'US/Alaska',
		id: 'timezone-us-alaska',
		text: 'US/Alaska',
	},
	{
		dataContext: 'US/Hawaii',
		id: 'timezone-us-hawaii',
		text: 'US/Hawaii',
	},
];

const AidaDayPickerBase: React.FC<IProps> = ({
	canAdjustTime = false,
	className = '',
	day,
	hideTime = false,
	onCancel,
	onSave,
	onChange,
}) => {
	const queue = useQueue();

	const userSession = useUserSession();

	const isLevitateSalesCoffeeAccount = userSession.account.isLevitateSalesCoffeeAccount;

	const initialTimezone = !isLevitateSalesCoffeeAccount
		? queue.lead?.company?.timeZone || 'America/Los_Angeles'
		: timezoneOptions.find(tz => tz.dataContext === queue.lead?.company?.timeZone) ??
			timezoneOptions.find(tz => tz.dataContext === 'US/Pacific');

	const [selectedTimezone, setSelectedTimezone] = useState<unknown>(initialTimezone);

	const correctTimezone = !isLevitateSalesCoffeeAccount
		? (selectedTimezone as timezones)
		: (selectedTimezone as ISelectOption<string>).dataContext;

	const timezoneLabel = timezoneMap[correctTimezone] || correctTimezone || '';

	useEffect(() => {
		if (day && canAdjustTime) {
			onChange(moment(getTimezoneUnadjustedTime(day.toDate(), correctTimezone)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// disable all days before today
	const getDisabledDays = () => [{ before: new Date() }];
	const onDayClick = (newDay: Date) => {
		const date = moment(newDay);
		date.set('hour', day.hour());
		date.set('minute', day.minute());
		onChange(date);
	};

	const onCancelClick = () => onCancel();

	const onRenderDay = (
		dayToRender: Date,
		modifiers: Partial<Modifiers> & IEditSendOptionsDayPickerModifiers,
		props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
	) => {
		if (modifiers?.selected) {
			return (
				<span className={`${props?.className || ''} ${css(styleSheet.dayPickerDateSelected)}`}>
					{dayToRender.getDate()}
				</span>
			);
		} else if (!modifiers?.disabled && !modifiers?.outside) {
			return (
				<span
					className={`${props?.className || ''} ${css(
						styleSheet.dayPickerDateNotSelected,
						modifiers?.today && styleSheet.dayPickerDateToday
					)}`}
				>
					{dayToRender.getDate()}
				</span>
			);
		}

		return null;
	};

	const onSaveClick = () => {
		if (!selectedTimezone) {
			return;
		}
		const tzAdjusted = getTimezoneAdjustedTime(day.toDate(), correctTimezone);
		onSave(moment(tzAdjusted));
	};

	const onTimeChange = (time: moment.Moment) => {
		const newDay = (!day ? moment() : day).clone();
		newDay.set('hours', time.hour());
		newDay.set('minutes', time.minute());
		newDay.set('seconds', 0);
		newDay.set('milliseconds', 0);
		onChange(newDay);
	};

	const onTimeZoneChanged = (tzData: ITimezoneData) => {
		setSelectedTimezone(tzData.name);
	};

	return (
		<div className={`${css(styleSheet.dayPickerContainer)} ${className}`}>
			<DayPicker
				canChangeToNextMonth={true}
				canChangeToPreviousMonth={true}
				className={css(styleSheet.dayPicker)}
				disabledDays={getDisabledDays()}
				onDayClick={onDayClick}
				onRenderDay={onRenderDay}
				selectedDays={day?.toDate()}
				showOverflowDates={false}
			/>
			{!hideTime && (
				<div className={css(styleSheet.timeContainer)}>
					<TimePicker
						defaultTime={
							day && canAdjustTime && selectedTimezone
								? moment(getTimezoneUnadjustedTime((day || moment()).toDate(), correctTimezone))
								: moment()
						}
						increment={15}
						onChange={onTimeChange}
					/>
					&nbsp;
					{!isLevitateSalesCoffeeAccount ? (
						<TimezonePicker onTimezoneSelected={onTimeZoneChanged} placeholder={timezoneLabel} />
					) : (
						<DeprecatedSelect
							options={timezoneOptions}
							onOptionClick={setSelectedTimezone}
							selectedOption={selectedTimezone as ISelectOption<string>}
							styles={[styleSheet.timezoneSelect]}
						/>
					)}
				</div>
			)}
			<div className={css(styleSheet.ctaContainer)}>
				<Button kind='reverse' onClick={onCancelClick} label='Cancel' />
				<Button onClick={onSaveClick} label='Save' />
			</div>
		</div>
	);
};

export const AidaDayPicker = observer(AidaDayPickerBase);
