/* eslint-disable react-hooks/exhaustive-deps */
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DealStageOptions, DemoStatusFilter, IRange, IUser } from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ISelectOption } from '../../../web/components/DeprecatedSelect';
import { CloserOwnerSelect } from '../../components/leadView/components/CloserOwnerSelect';
import { DatePicker } from '../../components/leadView/components/DatePicker';
import { DemoStatusSelect, demoStatusSelectOptions } from '../../components/leadView/components/DemoStatusSelect';
import { Group } from '../../components/leadView/components/Group';
import { OutcomeSelect } from '../../components/leadView/components/OutcomeSelect';
import { UserSelect } from '../../components/leadView/components/UserSelect';
import { IDemosFilterParams } from '../../entities/Demos/useDemos';
import { useQueryParameter } from '../../hooks/query';
import { ApiClient } from '../../services/api';
import { FieldKey } from '../../viewModels/form';
import { styleSheet } from './styles';

interface IFilterProps {
	filter?: IDemosFilterParams;
	onChange: (filter: IDemosFilterParams) => void;
}

const format = 'YYYY-MM-DD';

export const Filters: React.FC<IFilterProps> = ({ filter, onChange }) => {
	const [, dateQuery, setDateQuery] = useQueryParameter('date', null);
	const [, dealStageQuery, setDealStageQuery] = useQueryParameter('dealStage', null);
	const [, userIdQuery, setUserIdQuery] = useQueryParameter('userId', null);
	const [, demoPerformerUserIdQuery, setDemoPerformerUserIdQuery] = useQueryParameter('demoPerformerUserId', null);

	const [selectedUser, setSelectedUser] = useState<IUser>();
	const [selectedDemoPerformerUser, setSelectedDemoPerformerUser] = useState<IUser>();

	const [selectedDemoStatus, setDemoStatus] = useState<ISelectOption<string>>();

	const userSession = useUserSession();

	const client = new ApiClient(userSession);

	const isToday = dateQuery === 'today';

	const datesFromQuery = decodeURIComponent?.(dateQuery)?.split(',');
	const startDateFromQuery = moment(datesFromQuery?.[0]) || moment();
	const endDateFromQuery = moment(datesFromQuery?.[1]) || moment();

	const isUserSessionId = userSession.user.id === userIdQuery;

	const isDemoPerformerUserSessionId = userSession.user.id === demoPerformerUserIdQuery;

	useEffect(() => {
		const initialFilter: IDemosFilterParams = {};
		if (dateQuery && isToday) {
			initialFilter.start = moment().format(format);
			initialFilter.end = moment().format(format);
		}
		if (dateQuery && !isToday) {
			initialFilter.start = startDateFromQuery.format(format);
			initialFilter.end = endDateFromQuery.format(format);
		}
		if (userIdQuery) {
			if (!isUserSessionId) {
				client.getUserById(userIdQuery).then(({ value }) => setSelectedUser(value));
			}
			initialFilter.userId = userIdQuery;
		}
		if (demoPerformerUserIdQuery) {
			if (!isDemoPerformerUserSessionId) {
				client.getUserById(demoPerformerUserIdQuery).then(({ value }) => setSelectedDemoPerformerUser(value));
			}
			initialFilter.demoPerformerUserId = demoPerformerUserIdQuery;
		}
		if (dealStageQuery) {
			const currentDealStage = demoStatusSelectOptions.find(i => i.dataContext === dealStageQuery);

			const { dealStage } = getDealStageFilterWithDates(currentDealStage);
			setDemoStatus(currentDealStage);

			initialFilter.dealStage = dealStage;
		}
		onChange({ ...filter, ...initialFilter });
	}, []);

	useEffect(() => {
		setDealStageQuery(selectedDemoStatus?.dataContext);
	}, [selectedDemoStatus]);

	const handleUserChange = (user: IUser) => {
		setUserIdQuery(user?.id || null);
		setSelectedUser(user);
		onChange({ ...filter, userId: user?.id });
	};

	const handleCloserOwnerChange = (user: IUser) => {
		setDemoPerformerUserIdQuery(user?.id || null);
		setSelectedDemoPerformerUser(user);
		onChange({ ...filter, demoPerformerUserId: user?.id });
	};

	const handleOutcomeChange = (loadOutcomes: ISelectOption<FieldKey>[]) => {
		const filtered = loadOutcomes?.map(x => (x.dataContext === 'Unknown' ? null : x.dataContext));
		const outcomeName = !!filtered && filtered.length === 1 ? filtered[0] : null;

		onChange({ ...filter, outcomeName });
	};

	const getDealStageFilterWithDates = (status: ISelectOption<string>) => {
		let dealStage = null;

		let dates: IRange<Date> = null;
		switch (status?.dataContext) {
			case DemoStatusFilter.DemosToConfirm:
				dealStage = null;

				dates = null;

				delete filter.outcomeName;
				break;
			case DemoStatusFilter.DemosToReschedule:
				dealStage = DealStageOptions.AppointmentNoShow;
				dates = {
					end: moment().toDate(),
					start: moment().subtract(1, 'week').toDate(),
				};
				break;
			case DemoStatusFilter.UpcomingDemos:
				dealStage = [DealStageOptions.AppointmentScheduled, DealStageOptions.AppointmentRescheduled];
				dates = {
					end: moment().add(1, 'week').toDate(),
					start: moment().toDate(),
				};
				break;
			default:
				break;
		}
		return { dates, dealStage };
	};

	const handleDemoStatusChange = (status: ISelectOption<string>) => {
		const { dates, dealStage } = getDealStageFilterWithDates(status);

		onChange({ ...filter, dealStage, ...prepareDateFilterPayload(dates) });
		setDemoStatus(status);
	};

	const prepareDateFilterPayload = (date: IRange<Date>) => {
		if (!date) {
			return {};
		}
		const end = moment(date?.end).format(format);
		const start = moment(date?.start).format(format);

		setDateQuery(moment().diff(moment(date?.start)) > 0 && start === end ? 'today' : `${start},${end}`);
		return { end, start };
	};

	const handleDateChange = (date: IRange<Date>, cancelled?: boolean) => {
		if (cancelled && date) {
			return;
		}
		if (!date) {
			setDateQuery(null);

			delete filter.end;

			delete filter.start;

			onChange(filter);
			return;
		}
		onChange({ ...filter, ...prepareDateFilterPayload(date) });
	};

	const defaultDateRange: IRange<Date> = {};

	if (dateQuery && isToday) {
		defaultDateRange.start = moment().toDate();
		defaultDateRange.end = moment().toDate();
	}
	if (dateQuery && !isToday) {
		defaultDateRange.start = startDateFromQuery.toDate();
		defaultDateRange.end = endDateFromQuery.toDate();
	}

	let defaultUser = null;
	let defaultDemoPerformerUser = null;

	if (userIdQuery) {
		defaultUser = isUserSessionId ? userSession.user : selectedUser;
	}

	if (demoPerformerUserIdQuery) {
		defaultDemoPerformerUser = isDemoPerformerUserSessionId ? userSession.user : selectedDemoPerformerUser;
	}

	const shouldDisableOutcomes = !!filter?.dealStage;

	return (
		<Group className={css(styleSheet.filter)} hCentered={true}>
			<UserSelect onChange={handleUserChange} user={defaultUser} />

			<CloserOwnerSelect onChange={handleCloserOwnerChange} user={defaultDemoPerformerUser} />

			{/* @ts-ignore */}
			<DatePicker onChange={handleDateChange} dateRangeValue={dateQuery ? defaultDateRange : null} />
			<DemoStatusSelect onChange={handleDemoStatusChange} selected={selectedDemoStatus} />
			<OutcomeSelect disabled={shouldDisableOutcomes} onChange={handleOutcomeChange} />
		</Group>
	);
};
