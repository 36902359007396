import { css } from 'aphrodite';
import * as React from 'react';
import { DisclosureIcon } from '../../../../web/components/svgs/icons/DisclosureIcon';
import { brandPrimary, white } from '../../../styles/colors';
import { TransparentButton } from '../../TransparentButton';
import { styleSheet } from '../styles';

export enum SortOptions {
	Demos = 'demos',
	Dials = 'dials',
	ProspectingDials = 'prospectingDials',
	Connections = 'connections',
	Points = 'points',
}

// Map of sort options to display labels
export const displayLabels: Record<string, string | React.ReactNode> = {
	[SortOptions.Dials]: 'ALL',
	[SortOptions.ProspectingDials]: 'PROSPECT',
	[SortOptions.Points]: 'POINTS',
	[SortOptions.Connections]: "C's",
	[SortOptions.Demos]: 'DEMOS',
};

interface IProps {
	columnKey: string;
	isAsc?: boolean;
	onClickToSort: (key: string) => void;
	sortBy?: SortOptions;
	width?: number;
}

export const ListHeader: React.FC<IProps> = ({ columnKey, isAsc, onClickToSort, sortBy, width }) => {
	const handleClick = (key: string) => () => onClickToSort(key);
	return (
		<div className={css(styleSheet.leaderColumn)} style={{ width }}>
			<div className={css(styleSheet.headerContent)}>
				<span className={css(styleSheet.headerLabel)}>{displayLabels[columnKey] || columnKey}</span>
				<TransparentButton onClick={handleClick(columnKey)}>
					<DisclosureIcon
						className={css(styleSheet.arrowIcon, isAsc && styleSheet.arrowAsc)}
						fillColor={sortBy === columnKey ? white : brandPrimary}
					/>
				</TransparentButton>
			</div>
		</div>
	);
};
