import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { DeprecatedSelect, ISelectOption } from '../../../../../web/components/DeprecatedSelect';
import { Toggle } from '../../../../../web/components/Toggle';
import { DoubleArrowIcon } from '../../../../../web/components/svgs/icons/DoubleArrowIcon';
import { baseStyleSheet } from '../../../../../web/styles/styles';
import { useErrorMessages, useToaster } from '../../../../hooks';
import { navigation } from '../../../../styles/colors';
import {
	SlotMachineAchievementViewModel,
	SlotMachineAchievementsViewModel,
	SlotMachineGameViewModel,
} from '../../../../viewModels/slotMachines';
import { styleSheet } from './styles';

export interface IAchievementConfigurationTableRowProps {
	achievement: SlotMachineAchievementViewModel;
	achievements: SlotMachineAchievementsViewModel;
}

const DefaultSlotMachineTypeOptions: ISelectOption<Api.SlotMachineType>[] = [
	{
		dataContext: Api.SlotMachineType.Default,
		id: Api.SlotMachineType.Default,
		text: 'Default',
	},
	{
		dataContext: Api.SlotMachineType.GoldRush,
		id: Api.SlotMachineType.GoldRush,
		text: 'Gold Rush',
	},
	{
		dataContext: Api.SlotMachineType.BoozeCruise,
		id: Api.SlotMachineType.BoozeCruise,
		text: 'Booze Cruise',
	},
];

export const AchievementConfigurationTableRow: React.FC<IAchievementConfigurationTableRowProps> = observer(props => {
	const { achievement, achievements, children } = props;
	const { logApiError } = useEventLogging('AchievementConfigurationTableRow');
	const errorMessages = useErrorMessages();
	const [enabled, setEnabled] = React.useState<boolean>(achievement.configuration?.enabled);
	const [options, setOptions] = React.useState<ISelectOption<string>[]>(DefaultSlotMachineTypeOptions);
	const userSession = useUserSession();
	const toaster = useToaster();
	const selected = achievements.selectedAchievement === achievement;

	const onClick = React.useCallback(
		(e?: React.MouseEvent<HTMLElement>) => {
			e?.preventDefault();
			e?.stopPropagation();
			if (!achievement.game || !achievement.configuration?.enabled) {
				return;
			}

			achievements.selectedAchievement = achievements.selectedAchievement === achievement ? null : achievement;
		},
		[achievements, achievement]
	);

	const saveConfiguration = React.useCallback(
		async (config: typeof achievement.configuration) => {
			if (achievement.canSaveConfiguration(config)) {
				try {
					const opResult = await achievement.setConfiguration(config);

					if (selected && !opResult.value.enabled) {
						achievements.selectedAchievement = null;
					} else if (
						!achievements.selectedAchievement &&
						opResult.value.enabled &&
						opResult.value.rewardConfiguration?.gameId
					) {
						achievements.selectedAchievement = achievement;
					}
					return opResult;
				} catch (error) {
					logApiError('SaveConfiguration-Error', error);
					setEnabled(achievement.configuration?.enabled);
				}
			} else {
				if (config.enabled && !config.rewardConfiguration?.gameId) {
					toaster.push({
						customContent: <div>Please choose a game type to save.</div>,
						duration: 'short',
						type: 'custom',
					});
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[achievement, selected, achievements]
	);

	const selectOption = React.useCallback(
		async (option: ISelectOption<string>) => {
			if (option.dataContext === achievement.game?.type) {
				return;
			}

			let gameTemplate = Api.DefaultSlotMachineGameTemplates.find(x => x.type === option.dataContext);
			if (!gameTemplate) {
				errorMessages.push({
					messages: [`Unable to find a game template that matches "${option.dataContext}"`],
				});
				return;
			}
			gameTemplate = Api.excludeKeysOf(gameTemplate, ['id']);

			if (achievement.game) {
				const currentSlotMachineGame = achievement.game.toJs();
				const nextGame: Api.ISlotMachine = {
					...currentSlotMachineGame,
					...gameTemplate,
				};
				Api.copySlotMachineConfig(currentSlotMachineGame.slotMachineConfig, nextGame.slotMachineConfig);

				try {
					await achievement.game.update(nextGame);
				} catch (error) {
					logApiError('UpdateSlotMachineGame-Error', error);
				}
			} else {
				let slotMachineGame: SlotMachineGameViewModel = null;
				try {
					slotMachineGame = await SlotMachineGameViewModel.CreateNewGame(userSession, gameTemplate);
				} catch (error) {
					logApiError('CreateSlotMachineGame-Error', error);
					return;
				}

				try {
					await achievement.setGame(slotMachineGame, enabled);
				} catch (error) {
					logApiError('SetSlotMachineGame-Error', error);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[saveConfiguration, achievement, enabled]
	);

	React.useEffect(() => {
		if (achievement?.game?.isLoaded) {
			const index = DefaultSlotMachineTypeOptions.findIndex(x => x.dataContext === achievement.game.type);
			if (index >= 0) {
				setOptions(DefaultSlotMachineTypeOptions);
				selectOption(DefaultSlotMachineTypeOptions[index]);
				return;
			}

			const customGameOption = {
				dataContext: achievement.game.type,
				id: achievement.game.id,
				text: achievement.game.name,
			};

			setOptions([customGameOption, ...DefaultSlotMachineTypeOptions]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [achievement.game?.isLoaded]);

	const onToggleChanged = React.useCallback(
		(enable: boolean) => {
			setEnabled(enable);
			saveConfiguration({
				...achievement.configuration,
				enabled: enable,
			});
		},
		[achievement, saveConfiguration]
	);

	const onRenderSlotMachineTypeOptionsPlaceholder = React.useCallback(() => {
		return <div>Please select...</div>;
	}, []);

	return (
		<div className={css(styleSheet.row, !enabled ? baseStyleSheet.disabled : null)} onClick={onClick}>
			<div className={css(styleSheet.rowCard, styleSheet.rowActivityCard)}>
				<div className={css(styleSheet.rowToggleCol)}>
					<Toggle
						id={`achievement-toggle-${achievement.type}`}
						isOn={enabled}
						onToggleCheckChanged={onToggleChanged}
						uncheckedColor={navigation}
					/>
				</div>
				<div className={css(styleSheet.rowSettingsCol)}>
					<div className={css(styleSheet.rowActivityDescriptionCol)}>
						<div className={css(styleSheet.rowColHeader)}>Achievement</div>
						<div className={css(styleSheet.rowColContent)}>{achievement.description}</div>
					</div>
					<div className={css(styleSheet.rowSlotMachineSelectorColumn)}>
						<div className={css(styleSheet.rowColHeader)}>Slot Machine</div>
						<div className={css(styleSheet.rowColContent)}>
							<DeprecatedSelect
								onOptionClick={selectOption}
								options={options}
								selectedOption={options.find(x => x.dataContext === achievement.game?.type)}
								onRenderPlaceholder={onRenderSlotMachineTypeOptionsPlaceholder}
							/>
						</div>
					</div>
				</div>
				{children}
			</div>
			<button
				className={css(styleSheet.rowCard, styleSheet.rowPrizeCard, selected ? styleSheet.rowPrizeCardSelected : null)}
				disabled={!achievement.configuration?.enabled || !achievement.game}
				onClick={onClick}
			>
				<span className={css(styleSheet.rowPrizeCardHeader, selected ? styleSheet.rowPrizeCardHeaderSelected : null)}>
					Prizes
				</span>
				<span>{achievement?.game?.config?.payTable.filter(x => x.enabled)?.length || 0}</span>
				{selected ? <DoubleArrowIcon fillColor='#fff' /> : null}
			</button>
		</div>
	);
});
