import { css } from 'aphrodite';
import * as React from 'react';
import { useContext } from 'react';
import { getDisplayName } from '../../../../extViewmodels/Utils';
import { ViewportContext } from '../../../hooks/useViewport';
import { green } from '../../../styles/colors';
import { ILeaderboardUser } from '../../../viewModels/gamification';
import { styleSheet } from '../styles';
import { ListColumn } from './ListColumn';
import { ListHeader, SortOptions } from './ListHeader';

interface IProps {
	asc?: boolean;
	selected?: SortOptions;
	setAsc?(asc: boolean): void;
	setSortBy?(sort: string): void;
	sortBy?: SortOptions;
	top10: ILeaderboardUser[];
}

export const LeaderboardList: React.FC<IProps> = ({ asc, selected, setAsc, setSortBy, sortBy, top10 }) => {
	const { isBelowSmall } = useContext(ViewportContext);

	if (top10.length <= 0) {
		return null;
	}

	const handleSort = (sort: SortOptions) => {
		if (sort !== sortBy) {
			setSortBy(sort);
		} else {
			setAsc(asc === false ? true : false);
		}
	};

	const commons = {
		isAsc: asc === true,
		onClickToSort: handleSort,
		sortBy,
	};

	const show = (key: SortOptions) => {
		return !isBelowSmall || selected.toLowerCase() === key.toLowerCase();
	};

	const renderSorters = (
		<div className={css(styleSheet.leaderRow, styleSheet.leaderRowHeader)}>
			<div className={css(styleSheet.leaderName)} />

			{/* @ts-ignore */}
			{show(SortOptions.Points) && <ListHeader columnKey={SortOptions.Points} {...commons} />}

			{/* @ts-ignore */}
			{show(SortOptions.Dials) && <ListHeader columnKey={SortOptions.Dials} {...commons} />}

			{/* @ts-ignore */}
			{show(SortOptions.ProspectingDials) && <ListHeader columnKey={SortOptions.ProspectingDials} {...commons} />}

			{/* @ts-ignore */}
			{show(SortOptions.Connections) && <ListHeader columnKey={SortOptions.Connections} {...commons} />}

			{/* @ts-ignore */}
			{show(SortOptions.Demos) && <ListHeader columnKey={SortOptions.Demos} {...commons} width={80} />}
		</div>
	);

	const renderTop10 = top10.map(leader => {
		const userName = getDisplayName(leader.userReference);
		return (
			<div className={css(styleSheet.leaderRow)} key={leader.userReference.id}>
				<div className={css(styleSheet.leaderName)} title={userName}>
					{userName}
				</div>
				{show(SortOptions.Points) && <ListColumn color='#68D5FD' value={leader.score.points} />}
				{show(SortOptions.Dials) && <ListColumn value={leader.score.dials} />}
				{show(SortOptions.ProspectingDials) && <ListColumn value={leader.score.prospectingDials || 0} />}
				{show(SortOptions.Connections) && <ListColumn color={green} value={leader.score.connections} />}
				{show(SortOptions.Demos) && <ListColumn value={leader.score.demos} width={80} />}
			</div>
		);
	});

	return (
		<div className={css(styleSheet.leadersContainer)}>
			{renderSorters}
			{renderTop10}
		</div>
	);
};
