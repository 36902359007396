import * as React from 'react';
import { useState } from 'react';
import { DeprecatedSelect, ISelectOption } from '../../../../../web/components/DeprecatedSelect';
import { FieldKey } from '../../../../viewModels/form';
import { styleSheet } from '../styles';

interface IProps {
	disabled?: boolean;
	onChange?: (item: ISelectOption<FieldKey>) => void;
	options: ISelectOption<FieldKey>[];
	selected?: FieldKey;
}

export const VerticalSelect: React.FC<IProps> = ({ disabled, onChange, options, selected }) => {
	const [selectedOption, selectOption] = useState<FieldKey>(selected || FieldKey.Unknown);

	const onOutcomeClick = (option: ISelectOption<FieldKey>) => {
		onChange(option);
		selectOption(option?.dataContext);
	};

	return (
		<DeprecatedSelect
			dropdownStyles={[styleSheet.selectDropdown]}
			onOptionClick={onOutcomeClick}
			options={options}
			disabled={disabled}
			selectedOption={options.find(x => x.dataContext === selectedOption)}
			styles={[styleSheet.select]}
		/>
	);
};
