import { css } from 'aphrodite';
import * as React from 'react';
import { IPhoneNumber } from '../../../../extViewmodels';
import { PhoneNumberLabels } from '../../../../models';
import { getPartialFormattedPhoneNumber } from '../../../../models/UiUtils';
import { DeprecatedSelect, ISelectOption } from '../../../../web/components/DeprecatedSelect';
import { styleSheet } from '../styles';
import { IRepeaterItem, Repeater } from './Repeater';
import { TextFieldType } from './TextFieldType';

interface IProps {
	onChange: (values: IPhoneNumber[]) => void;
	values: IPhoneNumber[];
}

export const PhoneNumbersRepeater: React.FC<IProps> = ({ values, onChange }) => {
	const handleRepeaterChange = (newValues: IRepeaterItem<IPhoneNumber>[]) => {
		onChange(newValues.map(i => i.content));
	};

	const options: ISelectOption<string>[] = [...PhoneNumberLabels].map(p => ({
		dataContext: p.value,
		id: p.value,

		text: p.value[0].toUpperCase() + p.value.slice(1),
	}));

	return (
		<Repeater<IPhoneNumber> initialValues={values} onChange={handleRepeaterChange}>
			{(item, handleContentChange, itemIndex, items) => {
				const selectedLabel = options.find(o => o.dataContext === item?.content?.label) || options[0];
				const handleLabelSelect = (option: ISelectOption) => {
					handleContentChange({
						label: option.dataContext,
						value: item.content?.value,
					});
				};
				const handlePhoneChange = ({ value }: IPhoneNumber) => {
					if (value && value.startsWith('+')) {
						handleContentChange({
							label: item.content?.label,
							value,
						});
						return;
					}

					const regex = /\d+/g;
					const parsed = value.match(regex)?.join('') ?? '';
					const cleaned = parsed.replace(/\D/g, '');
					handleContentChange({
						label: item.content?.label,
						value: getPartialFormattedPhoneNumber(cleaned),
					});
				};
				return (
					<div className={css(styleSheet.labelWithPhoneRepeaterWrapper)}>
						<div>
							<TextFieldType
								label={`Phone ${items?.length > 1 ? itemIndex + 1 : ''}`}
								name={`phone-number-${item.uuid}`}
								onChange={handlePhoneChange}
								value={item.content?.value}
							/>
						</div>
						<div>
							<label>Label</label>
							<DeprecatedSelect
								onOptionClick={handleLabelSelect}
								options={options}
								selectedOption={selectedLabel}
								styles={[styleSheet.select]}
							/>
						</div>
					</div>
				);
			}}
		</Repeater>
	);
};
