import { StyleSheet } from 'aphrodite';
import { selectBoxHeight } from '../../../../../web/components/DeprecatedSelect/styles';
import { Layouts } from '../../../../../web/styles/styles';
import { brandPrimary, titles } from '../../../../styles/colors';

const rowHeight = 120;
const rowPrizeCardMargin = 10;
const rowSlotMachineSelectorColumnWidth = 140;
const rowToggleColWidth = 30;
const rowToggleColMargin = 20;
const rowActivityDescriptionColMargin = 26;

export const styleSheet = StyleSheet.create({
	container: {},
	row: {
		':not(:first-child)': {
			marginTop: 10,
		},
		alignItems: 'center',
		display: 'flex',
		height: rowHeight,
		transition: 'opacity 150ms ease-in-out',
	},
	rowActivityCard: {
		boxSizing: 'border-box',
		height: '100%',
		padding: '0 30px 0 20px',
		width: `calc(100% - ${rowHeight + rowPrizeCardMargin}px)`,
	},
	rowActivityDescriptionCol: {
		marginRight: rowActivityDescriptionColMargin,
		width: `calc(100% - ${rowSlotMachineSelectorColumnWidth}px)`,
	},
	rowCard: {
		alignItems: 'center',
		background: '#fff',
		borderRadius: 10,
		cursor: 'pointer',
		display: 'flex',
		position: 'relative',
	},
	rowColContent: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 14,
		minHeight: selectBoxHeight,
	},
	rowColHeader: {
		color: '#aaa',
		fontSize: 11,
		letterSpacing: '1.1px',
		marginBottom: 12,
		textTransform: 'uppercase',
	},
	rowPrizeCard: {
		...Layouts.verticalStack(8),
		alignItems: 'center',
		color: titles,
		diplay: 'flex',
		flexDirection: 'column',
		fontSize: 24,
		height: '100%',
		justifyContent: 'center',
		marginLeft: rowPrizeCardMargin,
		width: rowHeight,
	},
	rowPrizeCardHeader: {
		color: '#aaa',
		fontSize: 11,
	},
	rowPrizeCardHeaderSelected: {
		color: '#fff',
	},
	rowPrizeCardSelected: {
		':after': {
			background: brandPrimary,
			content: '""',
			height: '25%',
			left: '72%',
			position: 'absolute',
			top: '50%',
			transform: 'rotate(45deg) translate(0, -60%)',
			width: '25%',
			zIndex: -1,
		},
		background: brandPrimary,
		color: '#fff',
		position: 'relative',
		zIndex: 0,
	},
	rowSettingsCol: {
		display: 'flex',
		width: `calc(100% - ${rowToggleColWidth + rowToggleColMargin}px)`,
	},
	rowSlotMachineSelectorColumn: {
		width: rowSlotMachineSelectorColumnWidth,
	},
	rowToggleCol: {
		marginRight: rowToggleColMargin,
		width: rowToggleColWidth,
	},
});
