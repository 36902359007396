import { StyleSheet } from 'aphrodite';
import { FontFamilies } from '../../../../web/styles/styles';
import { background, brandPrimary, brandPrimaryHover, destructive, success, white } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	aiContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '16px',
	},
	aiAssistantContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		border: `1px solid ${background}`,
		borderRadius: '8px',
		overflow: 'hidden',
	},
	aiAssistantHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '12px 16px',
		background,
		borderBottom: `1px solid ${background}`,
	},
	aiAssistantHeaderLeft: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		color: brandPrimary,
	},
	aiAssistantTitle: {
		fontSize: '0.875rem',
		fontWeight: 'bold',
	},
	aiAssistantContent: {
		padding: '16px',
		minHeight: '200px',
		maxHeight: '400px',
		overflow: 'auto',
	},
	aiAssistantLoading: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '16px',
		height: '100%',
	},
	aiAssistantThinkingAvatar: {
		width: '64px',
		height: '64px',
	},
	aiAssistantResponse: {
		whiteSpace: 'pre-line',
		lineHeight: '1.5',
	},
	realtimeTranscriptionUtterances: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		overflow: 'auto',
		maxHeight: '200px',
		padding: '0 16px',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
		background: white,
		borderRadius: 8,
		width: '100%',
	},
	progressions: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		paddingBottom: 8,
		paddingLeft: 16,
		paddingRight: 16,
	},
	progression: {
		display: 'flex',
		alignItems: 'center',
		width: '30%',
		gap: 8,
	},
	isHovering: {
		fontFamily: FontFamilies.bold,
	},
	header: {
		textTransform: 'uppercase',
		display: 'flex',
		justifyContent: 'space-between',
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		background,
		gap: 6,
		color: brandPrimaryHover,
		padding: 8,
	},
	footer: {
		display: 'flex',
		justifyContent: 'center',
		background,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		padding: 8,
		gap: 6,
	},
	redCircle: {
		color: destructive,
		fontSize: 8,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	greenCircle: {
		color: success,
		fontSize: 8,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	green: {
		fontWeight: 600,
		color: success,
	},
	red: {
		fontWeight: 600,
		color: destructive,
	},
	oneQuarter: {
		width: '25%',
		alignItems: 'center',
	},
	oneHalf: {
		width: '50%',
		display: 'flex',
		gap: 8,
	},
	currentScore: {
		display: 'flex',
		justifyContent: 'center',
		gap: 6,
	},
	topicPopover: {
		padding: 12,
		maxWidth: 600,
		whiteSpace: 'pre-line',
	},
	// Test Transcription Component Styles
	testContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		padding: 24,
		background: white,
		borderRadius: 8,
		width: '100%',
		maxWidth: 800,
		margin: '0 auto',
	},
	testHeader: {
		fontSize: '1.5rem',
		fontWeight: 'bold',
		color: brandPrimary,
		marginBottom: 16,
		textAlign: 'center',
	},
	testToggleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 16,
		marginBottom: 8,
	},
	testInputContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		width: '100%',
	},
	testLabel: {
		fontWeight: 'bold',
		fontSize: '0.9rem',
	},
	testTextarea: {
		padding: 12,
		borderRadius: 4,
		border: `1px solid ${background}`,
		resize: 'vertical',
		fontFamily: 'inherit',
		fontSize: '0.9rem',
		lineHeight: 1.5,
		width: '100%',
		':focus': {
			outlineColor: brandPrimary,
		},
	},
	testSubmitButton: {
		alignSelf: 'center',
		marginTop: 8,
	},
	testLoading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 16,
		fontStyle: 'italic',
	},
	testResponseContainer: {
		marginTop: 16,
		padding: 16,
		border: `1px solid ${background}`,
		borderRadius: 8,
		backgroundColor: '#f8f9fa',
	},
	testResponseHeader: {
		fontSize: '1.1rem',
		fontWeight: 'bold',
		marginBottom: 8,
		color: brandPrimary,
	},
	testResponse: {
		whiteSpace: 'pre-line',
		lineHeight: 1.6,
		fontSize: '0.9rem',
	},
});
