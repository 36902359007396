import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { LiveScorecard } from '../../../components/ai/LiveScorecard';
import { useTelephony } from '../../../contexts/telephony';
import {
	invalidateGetScorecardByPhoneCallId,
	useGetScorecardsByPhoneCallIdQuery,
	useUpdatePhoneCallActiveScorecardMutation,
} from '../../../queries';
import { brandPrimary } from '../../../styles/colors';
import { AidaDraggable } from '../../AidaDraggable';
import { DraggableIcon } from '../../svgs/icons/audio/DraggableIcon';
import { TwilioContextProvider, useTwilio } from '../context';
import { styleSheet } from './styles';

export const ScorecardWrapper = ({
	phoneCall,
	isPhoneCallOngoing,
}: { phoneCall?: Api.IPhoneCall; isPhoneCallOngoing: boolean }) => {
	const [activeScorecard, setActiveScorecard] = React.useState<Api.IScorecard>(phoneCall?.activeScorecard);
	const [scorecards, setScorecards] = React.useState<Api.IScorecard[]>([]);
	const getScorecardsByPhoneCallIdQuery = useGetScorecardsByPhoneCallIdQuery({ phoneCallId: phoneCall?.id });
	const updatePhoneCallActiveScorecard = useUpdatePhoneCallActiveScorecardMutation({});

	React.useEffect(() => {
		if (!getScorecardsByPhoneCallIdQuery.data?.length) {
			return;
		}
		const fetchedScoreboards = getScorecardsByPhoneCallIdQuery.data;
		setScorecards(fetchedScoreboards);
		if (phoneCall.activeScorecardId) {
			const match = fetchedScoreboards.find(x => x.id === phoneCall.activeScorecardId);
			setActiveScorecard(match);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getScorecardsByPhoneCallIdQuery.data]);

	if (!scorecards?.length) return null;

	const gkScorecard = scorecards.find(x => x.persona === Api.ScorecardPersona.GateKeeper);
	const dmScorecard = scorecards.find(x => x.persona === Api.ScorecardPersona.DecisionMaker);

	const toggleActiveScorecard = async (newValue: Api.IScorecard) => {
		await updatePhoneCallActiveScorecard.mutateAsync({ phoneCallId: phoneCall.id, activeScorecardId: newValue.id });
		invalidateGetScorecardByPhoneCallId(phoneCall.id);
		setActiveScorecard(newValue);
	};

	const toggle = (
		<div className={css(styleSheet.buttonToggleGroup)}>
			{gkScorecard ? (
				<button
					className={css(
						styleSheet.buttonToggle,
						activeScorecard?.id === gkScorecard?.id ? styleSheet.activeButtonToggle : null
					)}
					onClick={() => toggleActiveScorecard(gkScorecard)}
				>
					GK
				</button>
			) : null}
			{dmScorecard ? (
				<button
					className={css(
						styleSheet.buttonToggle,
						activeScorecard?.id === dmScorecard?.id ? styleSheet.activeButtonToggle : null
					)}
					onClick={() => toggleActiveScorecard(dmScorecard)}
				>
					DM
				</button>
			) : null}

			<DraggableIcon fill={brandPrimary} className='sales-ai-drag-handle' />
		</div>
	);

	return (
		<div className={css(styleSheet.scorecardContainer)}>
			{activeScorecard ? (
				<LiveScorecard scorecard={activeScorecard} toggle={toggle} isPhoneCallOngoing={isPhoneCallOngoing} />
			) : null}

			{!activeScorecard ? (
				<div className={css(styleSheet.scorecardSelector)}>
					<h5>Choose a Score Card</h5>

					<p className={css(styleSheet.paragraph)}>
						The live score card will help you evaluate if your conversation is on the right track to close a deal.
					</p>

					<div className={css(styleSheet.pillButtonGroup)}>
						{gkScorecard ? (
							<button
								className={css(styleSheet.pillButton, styleSheet.redPill)}
								onClick={() => toggleActiveScorecard(gkScorecard)}
							>
								Gate Keeper
							</button>
						) : null}

						{dmScorecard ? (
							<button
								className={css(styleSheet.pillButton, styleSheet.greenPill)}
								onClick={() => toggleActiveScorecard(dmScorecard)}
							>
								Decision Maker
							</button>
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
};

export const SalesAi = () => {
	return (
		<TwilioContextProvider>
			<SalesAiContent />
		</TwilioContextProvider>
	);
};

const SalesAiContent = () => {
	const { phoneCall, lastPhoneCall } = useTelephony();
	const { startNewRealtimeTranscriptionUtterance } = useTwilio();

	const [dragging, setDragging] = React.useState<{
		active?: boolean;
		x?: number;
		y?: number;
	}>({ active: false, x: 0, y: 0 });

	// Load position from localStorage on component mount
	React.useEffect(() => {
		try {
			const savedPosition = localStorage.getItem('salesAiPosition');
			if (savedPosition) {
				const { x, y } = JSON.parse(savedPosition);
				setDragging(prev => ({ ...prev, x, y }));
			}
		} catch (error) {
			console.error('Error loading SalesAi position from localStorage:', error);
		}
	}, []);

	const onDrag = (event: DraggableEvent, { x, y }: DraggableData) => {
		event.stopPropagation();
		setDragging({ active: true, x, y });
	};

	const onDragStop = (_: DraggableEvent, { x, y }: DraggableData) => {
		const { active } = dragging;
		if (active) {
			setDragging({ active: false, x, y });

			// Save position to localStorage when drag stops
			try {
				localStorage.setItem('salesAiPosition', JSON.stringify({ x, y }));
			} catch (error) {
				console.error('Error saving SalesAi position to localStorage:', error);
			}
		}
	};

	React.useEffect(() => {
		if (phoneCall?.id && !lastPhoneCall?.id) {
			startNewRealtimeTranscriptionUtterance(phoneCall);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCall, lastPhoneCall]);

	if (!phoneCall && !lastPhoneCall) return null;

	return (
		<AidaDraggable
			onDrag={onDrag}
			onStop={onDragStop}
			defaultPosition={{ x: dragging.x, y: dragging.y }}
			position={{ x: dragging.x, y: dragging.y }}
			handle='.sales-ai-drag-handle'
			isDraggable={true}
		>
			<div className={`${css(styleSheet.floatingPanel)} sales-ai-drag-container`}>
				{phoneCall ? <ScorecardWrapper phoneCall={phoneCall} isPhoneCallOngoing={true} /> : null}
				{lastPhoneCall ? <ScorecardWrapper phoneCall={lastPhoneCall} isPhoneCallOngoing={false} /> : null}
			</div>
		</AidaDraggable>
	);
};
