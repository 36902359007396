/* eslint-disable sort-keys */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable import/no-internal-modules */
import * as Api from '../../../extViewmodels/sdk';
import { useState } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';

export enum NotesStoreKeyTypes {
	Notes = 'Notes',
}

export interface INotesStoreTimestampContent {
	content: Api.IRawRichTextContentState;
	plain: string;
}

export type INotesStoreCompaniesId = Record<string, INotesStoreTimestampContent>;

type StoreObject = Partial<Record<NotesStoreKeyTypes, INotesStoreCompaniesId>>;

const usePersistence = create<StoreObject, [['zustand/persist', StoreObject]]>(
	persist(() => ({}), {
		name: 'NotesStore',
		getStorage: () => localStorage,
	})
);

export const useNotesStore = (storeKey: NotesStoreKeyTypes) => {
	const initialState = usePersistence.getState()[storeKey];

	const [state, setState] = useState(initialState);

	const unsubscribe = usePersistence.subscribe(newState => {
		setState(newState[storeKey]);
	});

	const update = (data: INotesStoreCompaniesId) => {
		const internalStore = usePersistence.getState();
		internalStore[storeKey] = data;
		usePersistence.setState({
			...internalStore,
		});
	};

	const clear = () => {
		const internalStore = usePersistence.getState();
		delete internalStore[storeKey];
		usePersistence.setState({
			...internalStore,
		});
	};

	return {
		clear,
		unsubscribe,
		state,
		update,
	};
};
