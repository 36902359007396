import * as React from 'react';
import { TranscriptionStatusFilter } from '../../../../../extViewmodels';
import { DeprecatedSelect, ISelectOption } from '../../../../../web/components/DeprecatedSelect';
import { styleSheet } from './styles';

interface IProps {
	disabled?: boolean;
	onChange?: (item: ISelectOption<TranscriptionStatusFilter>) => void;
	selected?: ISelectOption<string>;
}

const options: ISelectOption<TranscriptionStatusFilter>[] = [
	{
		dataContext: null,
		id: 'all',
		text: 'All Statuses',
	},
	{
		dataContext: TranscriptionStatusFilter.TranscriptionComplete,
		id: TranscriptionStatusFilter.TranscriptionComplete,
		text: 'Transcribed',
	},
];

export const TranscriptionStatusSelect: React.FC<IProps> = ({ disabled, onChange, selected }) => {
	const onClick = (option: ISelectOption<TranscriptionStatusFilter>) => {
		onChange(option);
	};

	const selectedOption = options.find(x => x.dataContext === selected?.dataContext) || options[0];

	return (
		<DeprecatedSelect
			dropdownStyles={[styleSheet.selectDropdown]}
			onOptionClick={onClick}
			options={options}
			disabled={disabled}
			selectedOption={selectedOption}
			styles={[styleSheet.select]}
		/>
	);
};
