import * as React from 'react';
import { useEffect, useState } from 'react';
import { ISelectOption } from '../../../../web/components/DeprecatedSelect';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { IIntegrationPropertyItem } from '../../../viewModels/leads/interfaces';
import { ContentBox } from '../../generics/ContentBox';
import { useIntegrationProperties } from '../IntegrationPropertiesContext';
import { GeneralSettingsItem } from './GeneralSettingsItem';

interface IProps {
	onFetchStatusChange: (value: FetchStatus) => void;
}

export const sortPropertyItemsByKey = (
	array: IIntegrationPropertyItem[],
	key: keyof IIntegrationPropertyItem,
	invert?: boolean
) => {
	return (array || []).sort((a, b) => {
		if (a[key] < b[key]) {
			return invert ? 1 : -1;
		}

		if (a[key] > b[key]) {
			return invert ? -1 : 1;
		}
		return 0;
	});
};

export const LeadGeneralSettings: React.FC<IProps> = ({ onFetchStatusChange }) => {
	const { config, status, update } = useIntegrationProperties();

	const [selected, setSelected] = useState<ISelectOption>();

	const leadPoolPropertyName = config?.leadPoolPropertyName;

	useEffect(() => {
		onFetchStatusChange(status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	useEffect(() => {
		if (status === FetchStatus.Done) {
			const formattedSelected = config?.properties
				?.filter(i => i.name === leadPoolPropertyName)
				.map(i => ({
					dataContext: i.label,
					id: i.name,
					text: i.label,
				}))?.[0];

			setSelected(formattedSelected || null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadPoolPropertyName, status]);

	const handleSettingSelection = (option: ISelectOption) => {
		update({
			enableLeadPools: true,
			leadPoolPropertyName: option.id,
		});
	};

	const formattedOptions = sortPropertyItemsByKey(config?.properties, 'label').map(item => {
		return {
			dataContext: item.label,
			id: item.name,
			text: item.label,
		};
	});

	return (
		<ContentBox maxWidth={1024} title='General settings:'>
			<GeneralSettingsItem<string>
				defaultSelected={selected}
				description='Please select the property for lead pools'
				onSelect={handleSettingSelection}
				selectOptions={formattedOptions}
			/>
		</ContentBox>
	);
};
